import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import classNames from "./dashboard.module.scss";

//assets
import fullLogo from "../../assets/images/fulllogo.svg";
import logoutIcon from "../../assets/images/icons/logout.svg";
import { MainContext } from "../../context/context";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginData, token, setLoginData, selectedNav, setSelectedNav } =
    useContext(MainContext);

  const [navigation, setNavigation] = useState([
    { title: "Master", path: "/master" },
    { title: "Map", path: "/map" },
    { title: "Leads", path: "/leads" },
    { title: "Contact", path: "/contactus" },
    { title: "Settings", path: "/settings" },
  ]);

  function logoutFunction() {
    localStorage.removeItem("atozLoginData");
    localStorage.removeItem("atozToken");
    localStorage.removeItem("atozAdminStatus");
    setLoginData("");
    navigate("/");
    window.location.reload(false);
  }

  return (
    <div
      className={classNames.dashboard}
      style={{
        display: !token || location?.pathname == "/" ? "none" : "",
      }}
    >
      <div className={classNames.sidebar}>
        <div className={classNames.fullLogo}>
          <img src={fullLogo} alt="fullLogo" />
        </div>

        <div className={classNames.navigations}>
          {navigation?.length > 0 &&
            navigation?.map((eachNav, i) => {
              return (
                <Link
                  to={eachNav?.path}
                  className={`${classNames.eachNavigation} ${
                    selectedNav === eachNav?.title
                      ? classNames.eachNavigationSelected
                      : ""
                  } ${
                    window.location?.pathname?.includes(
                      eachNav?.title?.toLowerCase()
                    )
                      ? classNames.eachNavigationSelected
                      : ""
                  }`}
                  style={{
                    display:
                      (eachNav?.title !== "Settings" &&
                        eachNav?.title !== "Master" &&
                        eachNav?.title !== "Map") ||
                      (eachNav?.title === "Settings" &&
                        localStorage.getItem("atozAdminStatus") === "superadmin") ||
                      (eachNav?.title === "Master" &&
                        localStorage.getItem("atozAdminStatus") === "superadmin") ||
                      (eachNav?.title === "Map" &&
                        localStorage.getItem("atozAdminStatus") === "superadmin") 
                        ? "block"
                        : "none",
                  }}
                  key={eachNav?.title + i}
                  onClick={() => {
                    setSelectedNav(eachNav?.title);
                  }}
                >
                  {eachNav?.title}
                </Link>
              );
            })}
        </div>
      </div>
      <div className={classNames.logoutBtn} onClick={logoutFunction}>
        <img src={logoutIcon} alt="logoutIcon" />
        Logout
      </div>
    </div>
  );
};

export default Dashboard;
