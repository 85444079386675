import React, { useContext, useState } from "react";

import classNames from "./overlay.module.scss";
import { MainContext } from "../../context/context";

import {
  CustomDropdown,
  CustomDropdownCreate,
  CustomDropdownSingle,
  CustomDropdownSingleCreate,
  CustomImageInput,
  CustomImageInputCreate,
  CustomInput,
  CustomInputCreate,
  CustomSearch,
  CustomSearchCreate,
  CustomTextArea,
  CustomTextAreaCreate,
} from "../CustomInputs";

//assets
import adminIcon from "../../assets/images/icons/admin.svg";
import addadminIcon from "../../assets/images/icons/addadmin.svg";
import deleteadminIcon from "../../assets/images/icons/deleteadmin.svg";
import mailstatusIcon from "../../assets/images/icons/mailstatus.svg";
import deleteredIcon from "../../assets/images/icons/deletered.svg";
import successImage from "../../assets/images/success.svg";
import ToggleButton from "../Toggle";
import { GlobalOverflowLoading } from "../GlobalLoading";
import { addAdminAPI, deleteAdmin } from "../../assets/functions";

const AdminSettings = () => {
  const {
    token,
    selectedContent,
    overlayOpened,
    setOverlayOpened,
    setproductCreated,
    overflowLoading,
    setOverflowLoading,
    allAdminsList,
    addAdmin,
    setAddAdmin,
  } = useContext(MainContext);

  let roles = ["admin", "superadmin"];

  const [adminDeletePopup, setAdminDeletePopup] = useState(false);
  const [adminId, setAdminId] = useState("");

  function handleAddAdmin(event, field, valuee) {
    const { name, value } = event?.target;

    if (field == "role") {
      setAddAdmin((prev) => {
        return { ...prev, [field]: valuee };
      });
    } else if (name !== "") {
      // Only update state if field is not an empty string
      if (name === "images") {
        let imagesArray = Array.from(event.target.files);
        setAddAdmin((prev) => {
          return { ...prev, [name]: imagesArray };
        });
      } else {
        setAddAdmin((prev) => {
          return { ...prev, [name]: value };
        });
      }
    }
  }

  function renderInputs(eachItem, index) {
    if (
      selectedContent?.title?.toLowerCase()?.includes("update") ||
      selectedContent?.title?.toLowerCase()?.includes("edit")
    ) {
      switch (eachItem?.type) {
        case "CustomInput":
          return (
            <CustomInput
              {...eachItem}
              key={"CustomInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomTextArea":
          return (
            <CustomTextArea
              {...eachItem}
              key={"CustomTextArea" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdown":
          return (
            <CustomDropdown
              {...eachItem}
              key={"CustomDropdown" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomImageInput":
          return (
            <CustomImageInput
              {...eachItem}
              key={"CustomImageInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomSearch":
          return (
            <CustomSearch
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdownSingle":
          return (
            <CustomDropdownSingle
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        default:
          return;
      }
    } else {
      switch (eachItem?.type) {
        case "CustomInput":
          return (
            <CustomInputCreate
              {...eachItem}
              key={"CustomInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomTextArea":
          return (
            <CustomTextAreaCreate
              {...eachItem}
              key={"CustomTextArea" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdown":
          return (
            <CustomDropdownCreate
              {...eachItem}
              key={"CustomDropdown" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomImageInput":
          return (
            <CustomImageInputCreate
              {...eachItem}
              key={"CustomImageInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomSearch":
          return (
            <CustomSearchCreate
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdownSingle":
          return (
            <CustomDropdownSingleCreate
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        default:
          return;
      }
    }
  }

  return (
    <div className={classNames.adminSettings}>
      <div className={classNames.header}>
        <div>
          <img src={adminIcon} alt="adminIcon" />
          <span>{selectedContent?.title ? selectedContent?.title : ""}</span>
        </div>
        <span onClick={() => setOverlayOpened("")}>X</span>
      </div>
      <div className={classNames.content}>
        {overlayOpened == "adminsettings" ? (
          <div className={classNames.settingBody}>
            <div
              className={classNames.settingsOption}
              onClick={() => {
                setOverlayOpened("addadmin");
              }}
            >
              <img src={addadminIcon} alt="adminIcon" />
              <span>Add Admin</span>
            </div>
            <div
              className={classNames.settingsOption}
              onClick={() => {
                setOverlayOpened("deleteadmin");
              }}
            >
              <img src={deleteadminIcon} alt="resetpasswordIcon" />
              <span>Delete Admin</span>
            </div>
            <div
              className={classNames.settingsOption}
              onClick={() => {
                setOverlayOpened("adminmailstatus");
              }}
            >
              <img src={mailstatusIcon} alt="resetpasswordIcon" />
              <span>Mail Status</span>
            </div>
            <div className={classNames.btnDiv}>
              <div
                className={classNames.backBtn}
                onClick={() => setOverlayOpened("")}
              >
                Go Back
              </div>
            </div>
          </div>
        ) : overlayOpened == "adminmailstatus" ? (
          <div className={classNames.mailStatus}>
            <div className={classNames.logoContainer}>
              <img src={mailstatusIcon} alt="mailstatusIcon" />
            </div>
            <div className={classNames.listContainer}>
              {allAdminsList?.length > 0
                ? allAdminsList?.map((eachAdmin, index) => {
                    return (
                      <div className={classNames.eachPerson}>
                        <span>
                          {index + 1 + ". "}&nbsp;
                          {eachAdmin?.name ? eachAdmin?.name : ""}-
                          {eachAdmin?.email ? eachAdmin?.email : ""}
                        </span>
                        <ToggleButton
                          status={eachAdmin?.mailStatus}
                          adminId={eachAdmin?.admin_id}
                          field="admin"
                        />
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className={classNames.btnDiv}>
              <div
                className={classNames.backBtn}
                onClick={() => setOverlayOpened("")}
              >
                Done
              </div>
            </div>
          </div>
        ) : overlayOpened == "deleteadmin" ? (
          <div className={classNames.deleteAdmin}>
            <div className={classNames.logoContainer}>
              <img src={deleteadminIcon} alt="deleteadminIcon" />
            </div>
            <div className={classNames.listContainer}>
              {allAdminsList?.length > 0
                ? allAdminsList?.map((eachAdmin, index) => {
                    return (
                      <div
                        className={classNames.eachPerson}
                        key={"eachadmin remove" + index}
                      >
                        <span>
                          {index + 1 + ". "}&nbsp;
                          {eachAdmin?.name ? eachAdmin?.name : ""}-
                          {eachAdmin?.email ? eachAdmin?.email : ""}
                        </span>
                        <img
                          src={deleteredIcon}
                          alt="deleteredIcon"
                          onClick={() => {
                            setAdminId(eachAdmin?.admin_id);
                            setAdminDeletePopup((prev) => !prev);
                          }}
                        />
                      </div>
                    );
                  })
                : ""}
            </div>
            {adminDeletePopup && (
              <div className={classNames.deletePopup}>
                <div className={classNames.cnfmMessage}>
                  Do you want to delete the admin ?
                </div>
                <div className={classNames.cnfmBtns}>
                  <div
                    onClick={async () => {
                      setOverflowLoading(true);
                      // console.log(adminId, token, "delete admin");
                      let response = await deleteAdmin(adminId, token);
                      if (response?.data?.status) {
                        setOverlayOpened("admindeleted");
                        setproductCreated((prev) => !prev);
                        // console.log(response, "delete admin response");
                      } else {
                        setOverlayOpened("");
                      }
                      setOverflowLoading(false);
                    }}
                  >
                    Delete
                  </div>
                  <div
                    onClick={() => {
                      setAdminDeletePopup((prev) => !prev);
                    }}
                  >
                    Close
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : overlayOpened == "admindeleted" ? (
          <div className={classNames.resetPassDoneContainer}>
            <img src={successImage} alt="successImage" />
            <div className={classNames.passwordResetDone}>
              Deleted Admin Successfully
            </div>
            <div
              className={classNames.resetBtn}
              onClick={() => {
                setOverlayOpened("");
              }}
            >
              Go Back
            </div>
          </div>
        ) : overlayOpened == "addadmin" ? (
          <div className={classNames.addAdmin}>
            <div className={classNames.logoContainer}>
              <img src={addadminIcon} alt="deleteadminIcon" />
            </div>
            <div className={classNames.inputContainer}>
              <CustomDropdownSingleCreate
                field="role"
                dropdownItems={roles}
                handleChange={handleAddAdmin}
                typeoff="array"
              />
              <CustomInputCreate
                title="Enter Admin Name*"
                placeholder="Enter Admin Name"
                handleChange={handleAddAdmin}
                field="name"
              />
              <CustomInputCreate
                title="Enter Admin Email*"
                placeholder="Enter Admin Email"
                handleChange={handleAddAdmin}
                field="email"
              />
              <CustomInputCreate
                title="Enter Password*"
                placeholder="Enter Password"
                handleChange={handleAddAdmin}
                field="password"
              />
              <div className={classNames.btnDiv}>
                <div
                  className={classNames.backBtn}
                  onClick={async () => {
                    setOverflowLoading(true);
                    let addAdminResponse = await addAdminAPI(addAdmin, token);
                    // console.log(addAdminResponse, "addAdmin");
                    if (addAdminResponse?.data?.status) {
                      setproductCreated((prev) => !prev);
                      setOverlayOpened("addadminsuccessful");
                    } else {
                      setOverlayOpened("");
                    }
                    setOverflowLoading(false);
                  }}
                >
                  Add Admin
                </div>
              </div>
            </div>
          </div>
        ) : overlayOpened == "addadminsuccessful" ? (
          <div className={classNames.resetPassDoneContainer}>
            <img src={successImage} alt="successImage" />
            <div className={classNames.passwordResetDone}>
              Added Admin Successfully
            </div>
            <div
              className={classNames.resetBtn}
              onClick={() => {
                setOverlayOpened("");
              }}
            >
              Go Back
            </div>
          </div>
        ) : (
          selectedContent?.contents?.length > 0 &&
          selectedContent?.contents.map((eachitem, index) => {
            return eachitem?.align == "flex" ? (
              <div
                style={{ display: "flex", gap: "1.5rem" }}
                key={"flex" + index}
              >
                {eachitem?.content?.map((eachItem, index) => {
                  return renderInputs(eachItem, index);
                })}
              </div>
            ) : (
              renderInputs(eachitem, index)
            );
          })
        )}
        {/* <div
          className={classNames.resetBtn}
          style={{ display: overlayOpened == "resetpassword" ? "" : "none" }}
          onClick={() => {
            setOverlayOpened("resetpassworddone");
          }}
        >
          Reset Password
        </div> */}
      </div>
      {overflowLoading && <GlobalOverflowLoading />}
    </div>
  );
};

export default AdminSettings;
