import React from "react";

//assets
import fullLogo from "../../assets/images/fulllogo.svg";

import classNames from "./globalloading.module.scss";

const GlobalLoading = () => {
  return (
    <div className={classNames.globalLoading}>
      <img src={fullLogo} alt="fullLogo" />
    </div>
  );
};

export default GlobalLoading;

export const GlobalOverflowLoading = () => {
  return (
    <div className={classNames.globalOverflowLoading}>
      <img src={fullLogo} alt="fullLogo" />
    </div>
  );
};
