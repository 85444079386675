import React, { useContext, useState } from "react";

import classNames from "./overlay.module.scss";
import {
  CustomDropdown,
  CustomDropdownCreate,
  CustomDropdownSingle,
  CustomDropdownSingleCreate,
  CustomImageInput,
  CustomImageInputCreate,
  CustomInput,
  CustomInputCreate,
  CustomSearch,
  CustomSearchCreate,
  CustomTextArea,
  CustomTextAreaCreate,
} from "../CustomInputs";
import { MainContext } from "../../context/context";
import {
  createItemsMasterDashboard,
  createMappingFunc,
  updateMasterDashboard,
} from "../../assets/functions/master";
import { LeadsOverflow } from "../../pages/Leads";
import { GlobalOverflowLoading } from "../GlobalLoading";
import { updateCollectionsAPI } from "../../assets/functions/map";

const OverlayContent = () => {
  const {
    token,
    masterDropdown,
    selectedContent,
    overlayOpened,
    setOverlayOpened,
    //create states
    setproductCreated,
    createProduct,
    setCreateProduct,
    createCategory,
    createCollection,
    createBanner,
    createReview,
    createGallery,
    createMapping,
    overflowLoading,
    setOverflowLoading,
    productsWithId,
    selectedCollectionProducts,
    //update states
    updateCollection,
    selectedCollectionName,
    selectedId,
    globalUpdateItem,
    selectedItems, setSelectedItems
  } = useContext(MainContext);

  //no need to touch these, will provide each element with it's custom input
  function renderInputs(eachItem, index) {
    if (
      selectedContent?.title?.toLowerCase()?.includes("update") ||
      selectedContent?.title?.toLowerCase()?.includes("edit")
    ) {
      switch (eachItem?.type) {
        case "CustomInput":
          return (
            <CustomInput
              {...eachItem}
              key={"CustomInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomTextArea":
          return (
            <CustomTextArea
              {...eachItem}
              key={"CustomTextArea" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdown":
          return (
            <CustomDropdown
              {...eachItem}
              key={"CustomDropdown" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomImageInput":
          return (
            <CustomImageInput
              {...eachItem}
              key={"CustomImageInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomSearch":
          return (
            <CustomSearch
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdownSingle":
          return (
            <CustomDropdownSingle
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        default:
          return;
      }
    } else {
      switch (eachItem?.type) {
        case "CustomInput":
          return (
            <CustomInputCreate
              {...eachItem}
              key={"CustomInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomTextArea":
          return (
            <CustomTextAreaCreate
              {...eachItem}
              key={"CustomTextArea" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdown":
          return (
            <CustomDropdownCreate
              {...eachItem}
              key={"CustomDropdown" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomImageInput":
          return (
            <CustomImageInputCreate
              {...eachItem}
              key={"CustomImageInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomSearch":
          return (
            <CustomSearchCreate
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdownSingle":
          return (
            <CustomDropdownSingleCreate
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        default:
          return;
      }
    }
  }

  async function createDashboard() {
    if (overlayOpened == "createmapping") {
      createMappingFunc(
        "centralmapping/admin/api/v1/create",
        token,
        createMapping
      );
      setproductCreated((prev) => !prev);
      setOverlayOpened("");
    } else if (
      selectedContent?.title?.toLowerCase()?.includes("update") ||
      selectedContent?.title?.toLowerCase()?.includes("edit")
    ) {
      // console.log("update btn", selectedContent?.title);
      if (overlayOpened == "updatecollection") {
        setOverflowLoading(true);
        const productsList = selectedItems?.map(
          (product) => product.product_id
        );
        const resultObject = { products_list: productsList };
        // console.log(resultObject, "updateCollection");
        let updateCollections = await updateCollectionsAPI(
          selectedId,
          resultObject,
          token
        );
        setproductCreated((prev) => !prev);
        setOverlayOpened("");
        // console.log(updateCollections, "updateCollections");
        setOverflowLoading(false);
      } else {
        let { images, image, ...otherfields } = globalUpdateItem;
        let updateProduct;
        switch (masterDropdown) {
          case "Product":
            updateProduct = await updateMasterDashboard(
              "product/admin/api/v1/update/" + otherfields?.product_id,
              otherfields,
              token
            );
            setproductCreated((prev) => !prev);
            setOverlayOpened("");
            // console.log(updateProduct?.data?.status, "updated Product");
            // console.log(otherfields, "product update");
            break;
          case "Category":
            updateProduct = await updateMasterDashboard(
              "category/admin/api/v1/update/" + otherfields?.category_id,
              otherfields,
              token
            );
            setproductCreated((prev) => !prev);
            setOverlayOpened("");
            // console.log(updateProduct?.data?.status, "updated Product");
            // console.log(otherfields, "product update");
            break;
          case "Collection":
            updateProduct = await updateMasterDashboard(
              "collection/admin/api/v1/update/" + otherfields?.collection_id,
              otherfields,
              token
            );
            setproductCreated((prev) => !prev);
            setOverlayOpened("");
            // console.log(updateProduct?.data?.status, "updated Product");
            // console.log(otherfields, "product update");
            break;
          case "Review":
            updateProduct = await updateMasterDashboard(
              "review/admin/api/v1/update/" + otherfields?.review_id,
              otherfields,
              token
            );
            setproductCreated((prev) => !prev);
            setOverlayOpened("");
            // console.log(updateProduct?.data?.status, "updated Product");
            // console.log(otherfields, "product update");
            break;
          case "Gallery":
            updateProduct = await updateMasterDashboard(
              "gallery/admin/api/v1/update/" + otherfields?.gallery_id,
              otherfields,
              token
            );
            setproductCreated((prev) => !prev);
            setOverlayOpened("");
            // console.log(updateProduct?.data?.status, "updated gallery");
            // console.log(otherfields, "product update");
            break;
          default:
            break;
        }
      }
    } else {
      setOverflowLoading(true);
      switch (masterDropdown) {
        case "Product":
          let productData = await createItemsMasterDashboard(
            "product/admin/api/v1/create",
            token,
            createProduct
          );
          setOverflowLoading(false);
          setCreateProduct("");
          setproductCreated((prev) => !prev);
          setOverlayOpened("");
          // console.log(productData?.data?.data, "productData");
          break;
        case "Category":
          let categoryData = await createItemsMasterDashboard(
            "category/admin/api/v1/create",
            token,
            createCategory
          );
          setproductCreated((prev) => !prev);
          setOverflowLoading(false);
          setOverlayOpened("");
          // console.log(categoryData?.data?.data, "categoryData");
          break;
        case "Collection":
          let collectionData = await createItemsMasterDashboard(
            "collection/admin/api/v1/create",
            token,
            createCollection
          );
          setproductCreated((prev) => !prev);
          setOverflowLoading(false);
          setOverlayOpened("");
          // console.log(collectionData?.data?.data, "collectionData");
          break;
        case "Banner":
          let bannerData = await createItemsMasterDashboard(
            "banner/admin/api/v1/create",
            token,
            createBanner
          );
          setproductCreated((prev) => !prev);
          setOverflowLoading(false);
          setOverlayOpened("");
          // console.log(bannerData?.data?.data, "bannerData");
          break;
        case "Review":
          let reviewData = await createItemsMasterDashboard(
            "review/admin/api/v1/create",
            token,
            createReview
          );
          setproductCreated((prev) => !prev);
          setOverflowLoading(false);
          setOverlayOpened("");
          // console.log(reviewData?.data?.data, "reviewData");
          break;
        case "Gallery":
          let galleryData = await createItemsMasterDashboard(
            "gallery/admin/api/v1/create",
            token,
            createGallery
          );
          setproductCreated((prev) => !prev);
          setOverflowLoading(false);
          setOverlayOpened("");
          // console.log(galleryData?.data?.data, "galleryData");
          break;
        default:
          break;
      }
    }
  }

  return (
    <div
      className={`${classNames.overlayContent} ${
        overlayOpened == "viewmoreleads" ? classNames.overlayContentLeads : ""
      }`}
    >
      <div className={classNames.header}>
        <span>{selectedContent?.title ? selectedContent?.title : ""}</span>
        <span onClick={() => setOverlayOpened("")}>X</span>
      </div>
      <form className={classNames.content}>
        {overlayOpened == "updatecollection" ? (
          <div className={classNames.updateCollection}>
            <div className={classNames.collectionValueBox}>
              {selectedCollectionName ? selectedCollectionName : ""}
            </div>
            <CustomDropdown
              placeholder="Select Products*"
              // value={}
              field="products_list"
              handleChange={selectedContent?.createhandle}
              dropdownItems={productsWithId}
              selectedd={selectedCollectionProducts}
            />
          </div>
        ) : overlayOpened == "viewmoreleads" ? (
          <LeadsOverflow />
        ) : (
          selectedContent?.contents?.length > 0 &&
          selectedContent?.contents.map((eachitem, index) => {
            return eachitem?.align == "flex" ? (
              <div
                style={{ display: "flex", gap: "1.5rem" }}
                key={"flex" + index}
              >
                {eachitem?.content?.map((eachItem, index) => {
                  return renderInputs(eachItem, index);
                })}
              </div>
            ) : (
              renderInputs(eachitem, index)
            );
          })
        )}
      </form>

      <div className={classNames.footerBtns}>
        {selectedContent?.btn1 && (
          <div
            className={classNames.closeBtn}
            onClick={() => setOverlayOpened("")}
          >
            {selectedContent?.btn1 ? selectedContent?.btn1 : ""}
          </div>
        )}
        {selectedContent?.btn2 && (
          <div className={classNames.nextBtn} onClick={createDashboard}>
            {selectedContent?.btn2 ? selectedContent?.btn2 : ""}
          </div>
        )}
      </div>
      {overflowLoading && <GlobalOverflowLoading />}
    </div>
  );
};

export default OverlayContent;
