import { useContext, useEffect, useState } from "react";
import "./ToggleButton.css";
import { updateMailStatusAPI } from "../../assets/functions";
import { MainContext } from "../../context/context";
import {
  toggleValueBulk,
  toggleValueCollection,
  toggleValueProduct,
} from "../../assets/functions/master";

function ToggleButton({ status, adminId, field }) {
  const { token, setproductCreated, setOverlayOpened } =
    useContext(MainContext);

  const handleToggle = async () => {
    const updatedStatus = !status;
    if (field == "popularCollection") {
      let updateValueCollectionBulk = await toggleValueBulk(
        token,
        "collection/admin/api/v1/updateValuePackage/all"
      );
      console.log(updateValueCollectionBulk, "updateValueCollectionBulk");
    } else if (field == "popularProduct") {
      let updateValueProductBulk = await toggleValueBulk(
        token,
        "product/admin/api/v1/updateSeasonalItem/all"
      );
      console.log(updateValueProductBulk, "updateValueProductBulk");
    } else if (field == "admin") {
      let updateMailStatusResponse = await updateMailStatusAPI(
        adminId,
        updatedStatus,
        token
      );
      // console.log(updateMailStatusResponse, "updateMailStatusResponse");
    } else if (field === "collection") {
      let updateCollectionResponse = await toggleValueCollection(
        token,
        adminId
      );
      // console.log(updateCollectionResponse, "updateCollectionResponse");
    } else if (field == "product") {
      let updateValueProductResponse = await toggleValueProduct(token, adminId);
      // console.log(updateValueProductResponse, "updateValueProductResponse");
    }
    setproductCreated((prev) => !prev);
    // setOverlayOpened("");
  };

  return (
    <label
      className="toggle"
      style={{
        pointerEvents:
          (field == "popularProduct" || field == "popularCollection") &&
          status == true
            ? "none"
            : "",
      }}
    >
      <input type="checkbox" checked={status} onChange={handleToggle} />
      <span className="toggle-circle"></span>
    </label>
  );
}

export default ToggleButton;
