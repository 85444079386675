import axios from "axios";
import { URL } from "../static";
import { toast } from "react-toastify";

export async function getMapDropdowns(endpoint, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(URL + endpoint, { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function updateCollectionsAPI(id, body, token) {
  // console.log(body, token, "updatedCollection API");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(
      URL + "centralmapping/admin/api/v1/update/" + id,
      body,
      { headers }
    );
    if (response?.data?.status) {
      toast("Successfully updated collection!");
    } else {
      toast("Can't update collection try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function deleteCentralMapping(adminId, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.delete(
      URL + "centralmapping/admin/api/v1/delete/" + adminId,
      {
        headers,
      }
    );
    if (response?.data?.data?.status) {
      toast("Successfully deleted!");
    } else {
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast("Cannot delete mapping data!");
    return error;
  }
}
