import React, { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";

import classNames from "./mastercontent.module.scss";

//context
import { MainContext } from "../../context/context";

//assets
import editIcon from "../../assets/images/icons/edit.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import testImage from "../../assets/images/testimage.svg";
import {
  deleteItemsMasterDashboard,
  getItemDataWithId,
} from "../../assets/functions/master";

const ReviewTable = ({ dashboardLoading }) => {
  const { masterDashboard, masterSearch } = useContext(MainContext);
  return (
    <div className={classNames.reviewTable}>
      <div className={classNames.header}>
        <div>S. No.</div>
        <div>User Name</div>
        <div>Review</div>
        <div>User Image</div>
        <div>Actions</div>
      </div>
      <div className={classNames.body}>
        {dashboardLoading
          ? Array.from({ length: 3 }).map((_, index) => {
              return <EachListLoading key={"reviewloading" + index} />;
            })
          : masterDashboard?.length > 0
          ? masterDashboard
              ?.filter((eachitem) => {
                return (
                  eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
                  eachitem?.review_id?.toLowerCase()?.includes(masterSearch)
                );
              })
              .map((eachitem, index) => {
                return <EachList key={"review" + index} {...eachitem} />;
              })
          : ""}
      </div>
    </div>
  );
};

export default ReviewTable;

const EachList = ({ review_id, name, description, images }) => {
  const {
    token,
    setOverlayOpened,
    masterDeleteList,
    setMasterDeleteList,
    setGlobalUpdateItem,
  } = useContext(MainContext);
  const [reviewOpen, setReviewOpen] = useState(false);
  const firstTwoElements = images?.slice(0, 2);

  async function deleteItem(endpoint, tkn) {
    let result = await deleteItemsMasterDashboard(endpoint, tkn);
    if (result?.status) {
      setMasterDeleteList(!masterDeleteList);
    }
  }
  return (
    <div>
      <div>{review_id ? review_id : ""}</div>
      <div>{name ? name : ""}</div>
      <div className={`${reviewOpen ? classNames.reviewOpen : ""}`}>
        <span>{description ? description : ""}</span>
        <span onClick={() => setReviewOpen(!reviewOpen)}>
          {reviewOpen ? "READ LESS" : "READ MORE"}
        </span>
      </div>
      <div>
        {firstTwoElements?.length > 0 &&
          firstTwoElements?.map((eachimg, index) => {
            return <img src={eachimg} alt="" key={"productimg" + index} />;
          })}
      </div>
      <div>
        <div
          className={classNames.blueBtn}
          onClick={async () => {
            //had to add id so we can edit the specific item
            let reviewById = await getItemDataWithId(
              "Review/admin/api/v1/get/" + review_id,
              token
            );
            setGlobalUpdateItem(reviewById?.data?.data);
            // console.log(reviewById?.data?.data, "reviewById");
            setOverlayOpened("editreview");
          }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
        <div
          className={classNames.blackBtn}
          onClick={() =>
            deleteItem("review/admin/api/v1/delete/" + review_id, token)
          }
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
};

const EachListLoading = () => {
  const [reviewOpen, setReviewOpen] = useState(false);
  return (
    <div>
      <Skeleton width={70} height={20} />
      <Skeleton width={80} height={20} />
      <Skeleton width={130} height={20} />
      <div>
        <Skeleton width={40} height={40} />
      </div>
      <div>
        <Skeleton width={50} height={50} />
        <Skeleton width={50} height={50} />
      </div>
    </div>
  );
};
