import React, { useContext, useEffect, useState } from "react";

import classNames from "./map.module.scss";

//assets
import editIcon from "../../assets/images/icons/edit.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { MainContext } from "../../context/context";

//assets
import deleteIcon from "../../assets/images/icons/delete.svg";
import { getMapDashboard } from "../../assets/functions/master";
import Skeleton from "react-loading-skeleton";
import { deleteCentralMapping } from "../../assets/functions/map";

const Map = () => {
  const navigate = useNavigate();
  const {
    loginData,
    token,
    mapSearch,
    setMapSearch,
    productCreated,
    setGlobalLoading,
    setOverlayOpened,
  } = useContext(MainContext);
  const [selectedMap, setSelectedMap] = useState("Category");
  const [MapDashboard, setMapDashboard] = useState("");
  const [MapDashboardLoading, setMapDashboardLoading] = useState(false);

  async function renderSelectedMap() {
    setMapDashboardLoading(true);
    setMapDashboard("");
    switch (selectedMap) {
      case "Category":
        let categoryResponse = await getMapDashboard(
          "centralmapping/admin/api/v1/getcategory",
          token
        );
        // console.log(categoryResponse?.data?.data, "category reponse");
        setMapDashboardLoading(false);
        setMapDashboard(categoryResponse?.data?.data);
        break;
      case "Collection":
        let collectionResponse = await getMapDashboard(
          "centralmapping/admin/api/v1/getcollection",
          token
        );
        // console.log(collectionResponse?.data?.data, "collection reponse");
        setMapDashboardLoading(false);
        setMapDashboard(collectionResponse?.data?.data);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    renderSelectedMap();
  }, [selectedMap, productCreated]);

  // console.log(MapDashboard, "MapDashboard");

  if (!loginData && !token) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className={classNames.map}>
      <div className={classNames.contentTable}>
        <div className={classNames.mapOptions}>
          <div
            className={`${classNames.options} ${
              selectedMap == "Category" ? classNames.selectedOption : ""
            }`}
            onClick={(event) => setSelectedMap(event?.target?.innerText)}
          >
            Category
          </div>
          <div
            className={`${classNames.options} ${
              selectedMap == "Collection" ? classNames.selectedOption : ""
            }`}
            onClick={(event) => setSelectedMap(event?.target?.innerText)}
          >
            Collection
          </div>
        </div>
        <div className={classNames.searchBarHead}>
          <div className={classNames.searchBar}>
            <input
              type="text"
              placeholder="search"
              onChange={(event) =>
                setMapSearch(event?.target?.value?.toLowerCase())
              }
            />
            <div>Search</div>
          </div>
          <div
            className={classNames.createBtn}
            onClick={() => {
              setOverlayOpened("createmapping");
            }}
          >
            Create
          </div>
        </div>
        <div className={classNames.mapBody}>
          <div className={classNames.header}>
            <div>S. No.</div>
            <div>
              {selectedMap == "Category" ? "Category Name" : "Collection Name"}
            </div>
            <div>
              {selectedMap == "Category"
                ? "Collections Under The Category"
                : "Products Under The Collection"}
            </div>
            {selectedMap == "Collection" ? <div>Actions</div> : ""}
          </div>
          <div className={classNames.body}>
            {MapDashboardLoading
              ? Array.from({ length: 3 }).map((_, index) => {
                  return <EachListLoading key={"categoryloading" + index} />;
                })
              : MapDashboard?.length > 0 && selectedMap == "Category"
              ? MapDashboard?.filter((eachitem) => {
                  return (
                    (eachitem?.category?.length > 0 &&
                      eachitem?.category[0]?.category_id
                        ?.toLowerCase()
                        ?.includes(mapSearch)) ||
                    (eachitem?.category?.length > 0 &&
                      eachitem?.category[0]?.name
                        ?.toLowerCase()
                        ?.includes(mapSearch))
                  );
                }).map((eachitem, index) => {
                  return (
                    <EachList {...eachitem} key={"mapDashboard" + index} />
                  );
                })
              : MapDashboard?.length > 0 && selectedMap == "Collection"
              ? MapDashboard?.filter((eachitem) => {
                  return (
                    eachitem?.collections?.collection_id
                      ?.toLowerCase()
                      ?.includes(mapSearch) ||
                    eachitem?.collections?.name
                      ?.toLowerCase()
                      ?.includes(mapSearch)
                  );
                })?.map((eachitem, index) => {
                  return (
                    <EachListCollection
                      {...eachitem}
                      key={"mapDashboard" + index}
                    />
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;

const EachList = ({ category }) => {
  const { category_id, mapping_id, collections, name } =
    category?.length > 0 ? category[0] : "";
  const [productsUnder, setProductsUnder] = useState([
    "Barbeques",
    "Chaffing Dishes",
    "Cooking Equipment",
    "Electric Food Warmers",
  ]);

  // console.log(category, "category each item");
  const { token, setOverflowLoading, setproductCreated } =
    useContext(MainContext);

  const handleDelete = (index) => {
    const newProductsUnder = [...productsUnder];
    newProductsUnder.splice(index, 1);
    setProductsUnder(newProductsUnder);
  };

  return (
    <div className={classNames.eachListMap}>
      <div>{category_id ? category_id : ""}</div>
      <div>{name ? name : ""}</div>
      <div>
        {collections?.length > 0 &&
          collections[0]?.length > 0 &&
          collections?.map((eachItem, index) => {
            return (
              <span key={eachItem + index}>
                {eachItem[0]?.name}
                <span onClick={() => handleDelete(index)}>X</span>
              </span>
            );
          })}
      </div>
      {/* <div style={{ pointerEvents: "none", opacity: "0.75" }}>
        <div>
          <img src={editIcon} alt="editIcon" />
        </div>
        <div
          className={classNames.blackBtn}
          onClick={async () => {
            setOverflowLoading(true);
            let categoryResponse = await deleteCentralMapping(
              mapping_id,
              token
            );
            setproductCreated((prev) => !prev);
            console.log(categoryResponse, "categoryResponse");
            setOverflowLoading(false);
          }}
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div> */}
    </div>
  );
};

const EachListCollection = ({ collections, mapping_id }) => {
  const { collection_id, products, name } = collections ? collections : "";
  const [productsUnder, setProductsUnder] = useState([
    "Barbeques",
    "Chaffing Dishes",
    "Cooking Equipment",
    "Electric Food Warmers",
  ]);

  const {
    token,
    setOverlayOpened,
    setSelectedCollectionProducts,
    setSelectedCollectionName,
    setSelectedId,
    setOverflowLoading,
    setproductCreated,
  } = useContext(MainContext);

  // console.log(collections, "collections each item");

  const handleDelete = (index) => {
    const newProductsUnder = [...productsUnder];
    newProductsUnder.splice(index, 1);
    setProductsUnder(newProductsUnder);
  };

  return (
    <div className={classNames.eachListMap}>
      <div>{collection_id ? collection_id : ""}</div>
      <div>{name ? name : ""}</div>
      <div>
        {products?.length > 0 &&
          products?.map((eachItem, index) => {
            return (
              <span key={eachItem + index}>
                {eachItem?.name}
                <span onClick={() => handleDelete(index)}>X</span>
              </span>
            );
          })}
      </div>
      <div>
        <div
          onClick={() => {
            setOverlayOpened("updatecollection");
            setSelectedCollectionProducts(products);
            setSelectedCollectionName(name);
            setSelectedId(mapping_id);
            // console.log(mapping_id, products, name, "collection items");
          }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
        <div
          className={classNames.blackBtn}
          onClick={async () => {
            setOverflowLoading(true);
            // console.log(mapping_id, "mapping_id colelction");
            let collectionResponse = await deleteCentralMapping(
              mapping_id,
              token
            );
            setproductCreated((prev) => !prev);
            setOverflowLoading(false);
            // console.log(collectionResponse, "collectionResponse");
          }}
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
};

const EachListLoading = () => {
  return (
    <div>
      <Skeleton width={70} height={20} />
      <Skeleton width={110} height={20} />
      <Skeleton width={190} height={20} />
      <div>
        <Skeleton width={50} height={50} />
        <Skeleton width={50} height={50} />
      </div>
    </div>
  );
};
