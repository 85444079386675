import { Routes, Route } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Login from "./pages/Login";
import Dashboard from "./components/Dashboard";
import Master from "./pages/Master";
import Map from "./pages/Map";
import Settings from "./pages/Settings";
import Leads from "./pages/Leads";
import Overlay from "./components/Overlay";
import GlobalLoading from "./components/GlobalLoading";
import { MainContext } from "./context/context";
import ContactUs from "./pages/ContactUs";

function Routing() {
  const { globalLoading, overlayOpened } = useContext(MainContext);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Retrieve user role from local storage
    const storedUserRole = localStorage.getItem("atozAdminStatus");
    setUserRole(storedUserRole || "");
  }, []);

  const isSuperAdmin = userRole === "superadmin";

  return (
    <div className="routing">
      <Dashboard />
      <Routes>
        <Route path="/" element={<Login />} />
        {isSuperAdmin && <Route path="/master" element={<Master />} />}
        <Route path="/leads" element={<Leads />} />
        <Route path="/contactus" element={<ContactUs />} />
        {isSuperAdmin && <Route path="/map" element={<Map />} />}
        {isSuperAdmin && <Route path="/settings" element={<Settings />} />}
      </Routes>
      {overlayOpened && <Overlay />}
      {globalLoading && <GlobalLoading />}
    </div>
  );
}

export default Routing;
