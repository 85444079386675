import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./custominputs.module.scss";

//packages
import { v4 as uuidv4 } from "uuid";

//assets
import arrowIcon from "../../assets/images/icons/arrow.svg";
import placeholderImage from "../../assets/images/icons/uploadimage.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import { MainContext } from "../../context/context";
import {
  deleteImagesMasterDashboard,
  updateImagesAPI,
  uploadImageDashboardProduct,
} from "../../assets/functions/master";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const CustomInput = ({
  title,
  placeholder,
  state,
  value,
  field,
  handleChange,
}) => {
  const uniqueID = uuidv4();

  const { globalUpdateItem, selectedContent } = useContext(MainContext);

  return (
    <div className={classNames.customInput}>
      <label className={classNames.title} htmlFor={uniqueID}>
        {title ? title : ""}
      </label>
      <input
        type="text"
        placeholder={placeholder ? placeholder : ""}
        id={uniqueID}
        name={field}
        value={
          (globalUpdateItem &&
            selectedContent?.title?.toLowerCase()?.includes("update")) ||
          selectedContent?.title?.toLowerCase()?.includes("edit")
            ? globalUpdateItem[field]
            : ""
        }
        onChange={handleChange}
      />
    </div>
  );
};

export const CustomTextArea = ({
  title,
  placeholder,
  state,
  value,
  field,
  handleChange,
}) => {
  const uniqueID = uuidv4();
  const { globalUpdateItem, selectedContent } = useContext(MainContext);
  return (
    <div className={classNames.customTextarea}>
      <label className={classNames.title} htmlFor={uniqueID}>
        {title ? title : ""}
      </label>
      <textarea
        id={uniqueID}
        placeholder={placeholder ? placeholder : ""}
        name={field}
        value={
          (globalUpdateItem &&
            selectedContent?.title?.toLowerCase()?.includes("update")) ||
          selectedContent?.title?.toLowerCase()?.includes("edit")
            ? globalUpdateItem[field]
            : ""
        }
        onChange={handleChange}
      />
    </div>
  );
};

export const CustomDropdown = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
  selectedd,
}) => {
  const {
    globalUpdateItem,
    selectedContent,
    overlayOpened,
    firstTimeSuggested,
    setFirstTimeSuggested,
    selectedItems,
    setSelectedItems,
  } = useContext(MainContext);

  const [dropdown, setDropdown] = useState("");
  const [masterDropdown, setMasterDropdown] = useState(
    placeholder
      ? placeholder
      : dropdownItems?.length > 0
      ? dropdownItems[0]
      : ""
  );

  // const [selectedItems, setSelectedItems] = useState(
  //   selectedd ? selectedd : []
  // );
  const [selectedItemsId, setSelectedItemsId] = useState([]);

  useEffect(() => {
    if (selectedd) {
      setSelectedItems(selectedd);
    }
  }, [selectedd]);

  function isValidJson(value) {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    if (globalUpdateItem && firstTimeSuggested) {
      const { suggestions } = globalUpdateItem;
      // console.log(suggestions, "suggestions useeffect", firstTimeSuggested);
      let res = isValidJson(suggestions);
      if (Array.isArray(suggestions)) {
        setSelectedItems(suggestions);
        setFirstTimeSuggested(false);
      }
      // } else if (suggestions && res) {
      //   // console.log(suggestions, "suggestions");
      //   let productsArray = JSON?.parse(suggestions);
      //   setSelectedItems(suggestions ? productsArray : []);
      //   setFirstTimeSuggested(false);
      // }
    }
  }, [globalUpdateItem]);

  const dropdownArrayConverted = Object?.entries(dropdownItems)?.map(
    ([productId, name]) => ({
      value: productId,
      name,
    })
  );
  const dropdownArrayConvertedArray = Object?.entries(dropdownItems)?.map(
    ([productId, name]) => name
  );

  function filterSelectedItem(item) {
    let res = dropdownArrayConverted?.filter((eachitem) => {
      if (eachitem?.name == item || eachitem?.value == item) {
        return eachitem.name;
      }
    });
    // console.log(res?.length > 0 ? res[0].name : "", "filterSelectedItem");
    return res;
  }

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setDropdown("");
  }, [masterDropdown]);

  const handleDelete = (event, index) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedList = [...prevSelectedItems];
      updatedList.splice(index, 1);
      handleChange(event, field, updatedList);
      return updatedList;
    });

    setSelectedItemsId((prevSelectedItemsId) => {
      const updatedListId = [...prevSelectedItemsId];
      updatedListId.splice(index, 1);
      return updatedListId;
    });

    // handleChange(event, field, selectedItemsId);
  };

  // const handleDrag = (result) => {
  //   const { source, destination } = result;
  //   if (!destination) return; // Return if dropped outside a droppable area

  //   const newItems = Array.from(selectedItems);
  //   const [reorderedItem] = newItems.splice(source.index, 1);
  //   newItems.splice(destination.index, 0, reorderedItem);

  //   console.log(newItems, "newItems");
  //   setSelectedItems(newItems);
  // };

  const handleDrag = (result) => {
    if (!result.destination) return; // Return if the item was dropped outside a droppable area

    const { source, destination } = result;
    const newData = [...selectedItems];
    const [removed] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, removed);

    setSelectedItems(newData);
  };

  return (
    <div className={classNames.customDropdown}>
      <div
        className={classNames.dropdownBar}
        onClick={() => setDropdown(!dropdown)}
        ref={dropdownRef}
        style={{
          borderRadius: dropdown ? "5px 5px 0 0" : "",
          borderBottom: dropdown ? "none" : "",
        }}
      >
        <span>{masterDropdown ? masterDropdown : ""}</span>
        <img src={arrowIcon} alt="arrowIcon" />
        <div
          className={classNames.dropdownOptions}
          style={{ display: dropdown ? "" : "none" }}
        >
          {overlayOpened == "updatecollection" &&
          dropdownArrayConverted?.length > 0
            ? dropdownArrayConverted?.map((eachitem, i) => {
                return masterDropdown == eachitem?.name ? (
                  ""
                ) : (
                  <div
                    key={eachitem?.name + i}
                    name={field}
                    onClick={(event) => {
                      let convertedObj = {
                        product_id: eachitem?.value,
                        name: eachitem?.name,
                      };
                      setMasterDropdown(convertedObj?.name);
                      setSelectedItems([...selectedItems, convertedObj]);
                      handleChange(event, field, [
                        ...selectedItems,
                        convertedObj,
                      ]);
                      console.log(
                        convertedObj,
                        "updatecollection selected dropdown"
                      );
                    }}
                  >
                    {eachitem?.name}
                  </div>
                );
              })
            : typeof dropdownItems == "object"
            ? Object?.entries(dropdownItems)?.map(([key, value]) =>
                key === masterDropdown ? (
                  ""
                ) : (
                  <div
                    key={key}
                    name={field}
                    onClick={(event) => {
                      setMasterDropdown(value);
                      setSelectedItems((prevSelectedItems) => [
                        ...prevSelectedItems,
                        key,
                      ]);
                      setSelectedItemsId((prevSelectedItems) => [
                        ...prevSelectedItems,
                        key,
                      ]);
                      // console.log("objjj");
                      // console.log("objjj", key, value);
                      handleChange(event, field, [...selectedItems, key]);
                    }}
                  >
                    {value}
                  </div>
                )
              )
            : dropdownArrayConvertedArray?.length > 0 &&
              dropdownArrayConvertedArray?.map((eachitem, i) => {
                return masterDropdown == eachitem ? (
                  ""
                ) : (
                  <div
                    key={eachitem + i}
                    name={field}
                    onClick={(event) => {
                      setMasterDropdown(eachitem);
                      setSelectedItems([...selectedItems, eachitem]);
                      handleChange(event, field, [...selectedItems, eachitem]);
                      // console.log(
                      //   eachitem,
                      //   "updatecollection selected dropdown arr"
                      // );
                    }}
                  >
                    {eachitem}
                  </div>
                );
              })}
        </div>
      </div>
      <div className={classNames.selectedItems}>
        {overlayOpened == "updatecollection" && selectedItems?.length > 0 ? (
          <>
            {/* <DragDropContext onDragEnd={handleDrag}>
              {selectedItems?.map((eachItem, index) => {
                return (
                  <div key={eachItem?.name + index}>
                    <span>{eachItem?.name}</span>
                    <span onClick={(event) => handleDelete(event, index)}>
                      x
                    </span>
                  </div>
                );
              })}
            </DragDropContext> */}
            <DragDropContext onDragEnd={handleDrag}>
              <br />
              <table id="products">
                <thead>
                  <tr>
                    {/* <th>Product_id</th> */}
                    <th>Product name</th>
                    <th></th>
                  </tr>
                </thead>
                <Droppable droppableId="table">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {selectedItems?.map((item, index) => (
                        <Draggable
                          key={item.product_id}
                          draggableId={item.product_id}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {/* <td>{item.product_id}</td> */}
                              <td>{item.name}</td>
                              <td
                                onClick={(event) => handleDelete(event, index)}
                              >
                                Remove
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </>
        ) : selectedItems?.length > 0 ? (
          selectedItems.map((eachItem, index) => {
            const filteredName = filterSelectedItem(eachItem);
            if (filteredName.length === 0) {
              return null; // Skip rendering if the name is not found
            }
            const itemName = filteredName[0]; // Get the first name from the filtered array

            return (
              <div key={eachItem + index}>
                <span>
                  {itemName?.name ? itemName?.name : itemName ? itemName : ""}
                </span>
                <span onClick={(event) => handleDelete(event, index)}>x</span>
              </div>
            );
          })
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const CustomSearch = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const dropdownRef = useRef(null);
  const { globalUpdateItem, selectedContent } = useContext(MainContext);

  useEffect(() => {
    setSearchText("");
  }, [selectedItems]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchText("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDelete = (index) => {
    const updatedList = [...selectedItems];
    updatedList.splice(index, 1);
    setSelectedItems(updatedList);
  };

  return (
    <div className={classNames.customSearch} ref={dropdownRef}>
      <div className={classNames.searchDiv}>
        <input
          type="text"
          placeholder="Search Collection"
          onChange={(event) => setSearchText(event?.target?.value)}
          style={{ borderRadius: searchText ? "10px 0 0 0" : "" }}
        />
        <div
          className={classNames.searchBtn}
          style={{ borderRadius: searchText ? "0 10px 0 0" : "" }}
        >
          Search
        </div>
        <div
          className={classNames.dropdown}
          style={{ display: searchText ? "" : "none" }}
        >
          {dropdownItems?.length > 0 &&
            dropdownItems?.map((eachItem, index) => {
              return (
                <div key={eachItem + index}>
                  <span>{eachItem}</span>
                  <span
                    className={classNames.addBtn}
                    onClick={() =>
                      setSelectedItems([...selectedItems, eachItem])
                    }
                  >
                    +
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className={classNames.selectedItems}>
        {selectedItems?.length > 0 &&
          selectedItems?.map((eachItem, index) => {
            return (
              <div key={eachItem + index}>
                <span>{eachItem}</span>
                <span onClick={() => handleDelete(index)}>x</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const CustomImageInput = ({ title, field, handleChange }) => {
  const {
    token,
    overlayOpened,
    globalUpdateItem,
    selectedContent,
    setproductCreated,
    setOverlayOpened,
  } = useContext(MainContext);
  const uniqueID = uuidv4();

  const [image, setImage] = useState([]);

  async function deleteImage(eachItem) {
    let response;
    let ov = overlayOpened?.toLowerCase();
    if (ov?.includes("product")) {
      response = await deleteImagesMasterDashboard(
        "product/admin/api/v1/removeimage/" + globalUpdateItem?.product_id,
        token,
        eachItem
      );
    } else if (ov?.includes("category")) {
      response = await deleteImagesMasterDashboard(
        "category/admin/api/v1/removeimage/" + globalUpdateItem?.category_id,
        token,
        eachItem
      );
    } else if (ov?.includes("collection")) {
      response = await deleteImagesMasterDashboard(
        "collection/admin/api/v1/removeimage/" +
          globalUpdateItem?.collection_id,
        token,
        eachItem
      );
    } else if (ov?.includes("review")) {
      response = await deleteImagesMasterDashboard(
        "review/admin/api/v1/removeimage/" + globalUpdateItem?.review_id,
        token,
        eachItem
      );
    } else if (ov?.includes("gallery")) {
      response = await deleteImagesMasterDashboard(
        "gallery/admin/api/v1/removeimage/" + globalUpdateItem?.gallery_id,
        token,
        eachItem
      );
    }
    // console.log(response, "response from image delete function");
    setOverlayOpened("");
    setproductCreated((prev) => !prev);
  }

  async function updateImage() {
    // console.log(image, "image");
    let response;
    if (overlayOpened?.includes("category")) {
      response = await updateImagesAPI(
        "category/admin/api/v1/addimage/" + globalUpdateItem?.category_id,
        token,
        image
      );
      // console.log(response, "response category update");
    } else if (overlayOpened?.includes("collection")) {
      response = await updateImagesAPI(
        "collection/admin/api/v1/addimage/" + globalUpdateItem?.collection_id,
        token,
        image
      );
      // console.log(response, "response collections update");
    } else if (overlayOpened?.includes("review")) {
      response = await updateImagesAPI(
        "review/admin/api/v1/addimage/" + globalUpdateItem?.review_id,
        token,
        image
      );
      //  console.log(response, "response review update");
    } else if (overlayOpened?.includes("gallery")) {
      response = await updateImagesAPI(
        "gallery/admin/api/v1/addimage/" + globalUpdateItem?.gallery_id,
        token,
        image
      );
      //  console.log(response, "response review update");
    }
    setOverlayOpened("");
    setproductCreated((prev) => !prev);
  }

  return (
    <div className={classNames.customImageInput}>
      <label className={classNames.title}>{title ? title : ""}</label>
      {(globalUpdateItem &&
        selectedContent?.title?.toLowerCase()?.includes("update")) ||
      (selectedContent?.title?.toLowerCase()?.includes("edit") &&
        globalUpdateItem[field]?.length > 0) ? (
        globalUpdateItem[field]?.map((eachItem) => {
          return (
            <div className={classNames.inputContainer}>
              <label
                htmlFor={uniqueID}
                style={{
                  pointerEvents: overlayOpened?.includes("product")
                    ? "none"
                    : "",
                }}
              >
                <img
                  src={eachItem ? eachItem : placeholderImage}
                  alt="uploadImage"
                  style={{
                    width: eachItem ? "100%" : "",
                    height: eachItem ? "100%" : "",
                  }}
                />
              </label>
              <input
                type="file"
                id={uniqueID}
                multiple
                onChange={(event) => {
                  const file = event?.target?.files[0];
                  setImage(file);
                }}
                name={field ? field : ""}
              />
              <div className={classNames.btnsContainer}>
                <div
                  className={classNames.btn}
                  onClick={updateImage}
                  style={{
                    display: overlayOpened?.includes("product") ? "none" : "",
                  }}
                >
                  Update
                </div>
                <div
                  className={classNames.deleteBtn}
                  onClick={() => {
                    deleteImage(eachItem);
                  }}
                  style={{
                    display:
                      overlayOpened !== "editcategory" &&
                      overlayOpened !== "editcollection"
                        ? ""
                        : "none",
                  }}
                >
                  <img src={deleteIcon} alt="deleteIcon" />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className={classNames.inputContainer}>
          <label htmlFor={uniqueID}>
            <img src={placeholderImage} alt="uploadImage" />
          </label>
          <input
            type="file"
            id={uniqueID}
            multiple
            onChange={handleChange}
            name={field ? field : ""}
          />
          <div className={classNames.btnsContainer}>
            {/* <div className={classNames.btn}>Update</div>
            <div className={classNames.deleteBtn}>
              <img src={deleteIcon} alt="deleteIcon" />
            </div> */}
          </div>
        </div>
      )}
      {overlayOpened?.includes("product") && (
        <div className={classNames.inputContainer}>
          <label htmlFor={uniqueID + "singleupdate"}>
            <img src={placeholderImage} alt="uploadImage" />
          </label>
          <input
            type="file"
            id={uniqueID + "singleupdate"}
            onChange={(event) => {
              // console.log(globalUpdateItem[field]?.length, "lengthhh");
              const file = event.target.files[0];
              setImage({ images: [file] });
            }}
            name={field ? field : ""}
          />
          <div className={classNames.btnsContainer}>
            <div
              className={classNames.btn}
              onClick={async () => {
                let uploadimage = await uploadImageDashboardProduct(
                  token,
                  globalUpdateItem?.product_id,
                  image
                );
                if (uploadimage?.data?.status) {
                  setOverlayOpened("");
                  setproductCreated((prev) => !prev);
                }
                // console.log(uploadimage, "uploadimage image");
              }}
            >
              Upload
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const CustomDropdownSingle = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
  width,
  background,
  typeoff,
}) => {
  const { globalUpdateItem, selectedContent } = useContext(MainContext);
  const [dropdown, setDropdown] = useState("");
  const [masterDropdown, setMasterDropdown] = useState(
    placeholder
      ? placeholder
      : dropdownItems?.length > 0
      ? dropdownItems[0]
      : ""
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setDropdown("");
  }, [masterDropdown]);

  return (
    <div
      className={classNames.customDropdown}
      style={{
        width: width ? width : "",
      }}
    >
      <div
        className={classNames.dropdownBar}
        onClick={() => setDropdown(!dropdown)}
        ref={dropdownRef}
        style={{
          borderRadius: dropdown ? "5px 5px 0 0" : "",
          borderBottom: dropdown ? "none" : "",
          background: background ? background : "",
        }}
      >
        <span>{masterDropdown ? masterDropdown : ""}</span>
        <img src={arrowIcon} alt="arrowIcon" />
        <div
          className={classNames.dropdownOptions}
          style={{
            display: dropdown ? "" : "none",
            background: background ? background : "",
          }}
        >
          {!typeoff && typeof dropdownItems == "object"
            ? Object?.entries(dropdownItems)?.map(([key, value]) => {
                return key === masterDropdown ? (
                  ""
                ) : (
                  <div
                    key={key}
                    name={field}
                    onClick={(event) => {
                      // console.log(value, "selectedItem obj");
                      setMasterDropdown(value);
                      handleChange(event, field, key, value);
                    }}
                  >
                    {value}
                  </div>
                );
              })
            : dropdownItems?.length > 0 &&
              dropdownItems?.map((eachitem, i) => {
                return masterDropdown == eachitem ? (
                  ""
                ) : (
                  <div
                    key={eachitem + i}
                    name={field}
                    onClick={(event) => {
                      // console.log(eachitem, "selectedItem array");
                      setMasterDropdown(eachitem);
                      handleChange(event, field, eachitem);
                    }}
                  >
                    {eachitem ? eachitem.toUpperCase() : ""}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export const CustomInputCreate = ({
  title,
  placeholder,
  state,
  value,
  field,
  handleChange,
}) => {
  const uniqueID = uuidv4();

  return (
    <div className={classNames.customInput}>
      <label className={classNames.title} htmlFor={uniqueID}>
        {title ? title : ""}
      </label>
      <input
        type="text"
        placeholder={placeholder ? placeholder : ""}
        id={uniqueID}
        name={field}
        onChange={handleChange}
      />
    </div>
  );
};

export const CustomTextAreaCreate = ({
  title,
  placeholder,
  state,
  value,
  field,
  handleChange,
}) => {
  const uniqueID = uuidv4();
  return (
    <div className={classNames.customTextarea}>
      <label className={classNames.title} htmlFor={uniqueID}>
        {title ? title : ""}
      </label>
      <textarea
        id={uniqueID}
        placeholder={placeholder ? placeholder : ""}
        name={field}
        onChange={handleChange}
      />
    </div>
  );
};

export const CustomDropdownCreate = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
}) => {
  const [dropdown, setDropdown] = useState("");
  const [masterDropdown, setMasterDropdown] = useState(
    placeholder
      ? placeholder
      : dropdownItems?.length > 0
      ? dropdownItems[0]
      : ""
  );
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsId, setSelectedItemsId] = useState([]);
  const { overlayOpened } = useContext(MainContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDropdownItems, setFilteredDropdownItems] =
    useState(dropdownItems);

  const dropdownRef = useRef(null);

  const dropdownArrayConverted = Object?.entries(dropdownItems)?.map(
    ([productId, name]) => ({
      value: productId,
      name,
    })
  );
  const dropdownArrayConvertedArray = Object?.entries(dropdownItems)?.map(
    ([productId, name]) => name
  );

  // console.log(dropdownArrayConverted, "dropdownArrayConverted");

  function filterSelectedItem(item) {
    let res = dropdownArrayConverted?.filter((eachitem) => {
      if (eachitem?.name == item || eachitem?.value == item) {
        return eachitem.name;
      }
    });
    // console.log(res?.length > 0 ? res[0].name : "", "filterSelectedItem");
    return res;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setDropdown("");
  }, [masterDropdown]);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    // Filter the dropdown items based on the search query
    const filteredItems = Object.entries(dropdownItems).filter(
      ([key, value]) => {
        // You can modify the condition based on your desired search logic
        return value.toLowerCase().includes(query.toLowerCase());
      }
    );
    // console.log(dropdownItems, "dropdownItems");
    // console.log(filteredItems, "filteredItems", query, "query");
    const convertedData = filteredItems.reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});
    setFilteredDropdownItems(convertedData);
  };
  const handleInputClick = (event) => {
    event.stopPropagation();
    setSearchQuery(""); // Clear the search query when input is clicked
  };

  const handleDelete = (event, index) => {
    const updatedList = [...selectedItems];
    updatedList.splice(index, 1);
    const updatedListId = [...selectedItemsId];
    updatedListId.splice(index, 1);
    setSelectedItems(updatedList);
    setSelectedItemsId(updatedListId);
    handleChange(event, field, [...updatedListId]);
  };

  return (
    <div className={classNames.customDropdown}>
      <div
        className={classNames.dropdownBar}
        onClick={() => setDropdown(!dropdown)}
        ref={dropdownRef}
        style={{
          borderRadius: dropdown ? "5px 5px 0 0" : "",
          borderBottom: dropdown ? "none" : "",
        }}
      >
        <span>{masterDropdown ? masterDropdown : ""}</span>
        <img src={arrowIcon} alt="arrowIcon" />
        <div
          className={classNames.dropdownOptions}
          style={{ display: dropdown ? "" : "none" }}
        >
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchInputChange}
            onClick={handleInputClick}
            placeholder="Search..."
          />
          {typeof dropdownItems == "object"
            ? Object?.entries(filteredDropdownItems)?.map(([key, value]) =>
                key === masterDropdown ? (
                  ""
                ) : (
                  <div
                    key={key}
                    name={field}
                    onClick={(event) => {
                      setMasterDropdown(value);
                      setSelectedItems((prevSelectedItems) => [
                        ...prevSelectedItems,
                        key,
                      ]);
                      setSelectedItemsId((prevSelectedItems) => [
                        ...prevSelectedItems,
                        key,
                      ]);
                      // console.log("objjj");
                      // console.log("objjj", key, value);
                      handleChange(event, field, [...selectedItems, key]);
                    }}
                  >
                    {value}
                  </div>
                )
              )
            : filteredDropdownItems?.length > 0 &&
              filteredDropdownItems?.map((eachitem, i) => {
                return masterDropdown == eachitem ? (
                  ""
                ) : (
                  <div
                    key={eachitem + i}
                    name={field}
                    onClick={(event) => {
                      setMasterDropdown(eachitem);
                      setSelectedItems([...selectedItems, eachitem]);
                      handleChange(event, field, [...selectedItems, eachitem]);
                      // console.log("array", eachitem);
                    }}
                  >
                    {eachitem}
                  </div>
                );
              })}
        </div>
      </div>
      <div className={classNames.selectedItems}>
        {selectedItems?.length > 0 &&
          selectedItems.map((eachItem, index) => {
            const filteredName = filterSelectedItem(eachItem);
            if (filteredName.length === 0) {
              return null; // Skip rendering if the name is not found
            }
            const itemName = filteredName[0]; // Get the first name from the filtered array

            return (
              <div key={eachItem + index}>
                <span>
                  {itemName?.name ? itemName?.name : itemName ? itemName : ""}
                </span>
                <span onClick={(event) => handleDelete(event, index)}>x</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const CustomSearchCreate = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const dropdownRef = useRef(null);

  useEffect(() => {
    setSearchText("");
  }, [selectedItems]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSearchText("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDelete = (index) => {
    const updatedList = [...selectedItems];
    updatedList.splice(index, 1);
    setSelectedItems(updatedList);
  };

  return (
    <div className={classNames.customSearch} ref={dropdownRef}>
      <div className={classNames.searchDiv}>
        <input
          type="text"
          placeholder="Search Collection"
          onChange={(event) => setSearchText(event?.target?.value)}
          style={{ borderRadius: searchText ? "10px 0 0 0" : "" }}
        />
        <div
          className={classNames.searchBtn}
          style={{ borderRadius: searchText ? "0 10px 0 0" : "" }}
        >
          Search
        </div>
        <div
          className={classNames.dropdown}
          style={{ display: searchText ? "" : "none" }}
        >
          {dropdownItems?.length > 0 &&
            dropdownItems?.map((eachItem, index) => {
              return (
                <div key={eachItem + index}>
                  <span>{eachItem}</span>
                  <span
                    className={classNames.addBtn}
                    onClick={() =>
                      setSelectedItems([...selectedItems, eachItem])
                    }
                  >
                    +
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className={classNames.selectedItems}>
        {selectedItems?.length > 0 &&
          selectedItems?.map((eachItem, index) => {
            return (
              <div key={eachItem + index}>
                <span>{eachItem}</span>
                <span onClick={() => handleDelete(index)}>x</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const CustomImageInputCreate = ({ title, field, handleChange }) => {
  const uniqueID = uuidv4();

  //  setCreateProduct((prev) => {
  //    return { ...prev, [name]: imagesArray };
  //  });

  const { overlayOpened, createProduct, setCreateProduct } =
    useContext(MainContext);

  const [image, setImage] = useState([]);

  const handleDelete = (index) => {
    const updatedList = [...createProduct[field]];
    updatedList.splice(index, 1);
    setCreateProduct((prev) => {
      return { ...prev, [field]: updatedList };
    });
  };
  const handleChangeAddImage = (event) => {
    let imagesArray = Array.from(event.target.files);
    setCreateProduct((prev) => {
      return { ...prev, [field]: [...createProduct[field], ...imagesArray] };
    });
  };

  return (
    <div className={classNames.customImageInput}>
      <label className={classNames.title}>{title ? title : ""}</label>
      {createProduct[field]?.length > 0 &&
      overlayOpened?.includes("product") ? (
        createProduct[field]?.map((eachImage, index) => {
          return (
            <div className={classNames.inputContainer}>
              <label htmlFor={uniqueID}>
                <img src={placeholderImage} alt="uploadImage" />
              </label>
              <input
                type="file"
                id={uniqueID}
                multiple
                onChange={handleChange}
                name={field}
              />
              <span className={classNames.imageTitle}>{eachImage?.name}</span>
              <div className={classNames.btnsContainer}>
                {/* <div className={classNames.btn}>Update</div> */}
                <div
                  className={classNames.deleteBtn}
                  onClick={() => handleDelete(index)}
                >
                  <img src={deleteIcon} alt="deleteIcon" />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className={classNames.inputContainer}>
          <label htmlFor={uniqueID}>
            <img src={placeholderImage} alt="uploadImage" />
          </label>
          <input
            type="file"
            id={uniqueID}
            multiple
            onChange={handleChange}
            name={field}
          />
          <div className={classNames.btnsContainer}>
            {/* <div className={classNames.btn}>Update</div> */}
            <div className={classNames.deleteBtn}>
              <img src={deleteIcon} alt="deleteIcon" />
            </div>
          </div>
        </div>
      )}
      {createProduct[field]?.length > 0 &&
      overlayOpened?.includes("product") ? (
        <div className={classNames.inputContainer}>
          <label htmlFor={uniqueID + "newsingleupload"}>
            <img src={placeholderImage} alt="uploadImage" />
          </label>
          <input
            type="file"
            id={uniqueID + "newsingleupload"}
            multiple
            onChange={handleChangeAddImage}
            name={field}
          />
          <div className={classNames.btnsContainer}>
            {/* <div
              className={classNames.btn}
              onClick={() => console.log(createProduct, "createProduct")}
            >
              Update
            </div> */}
            {/* <div className={classNames.deleteBtn}>
              <img src={deleteIcon} alt="deleteIcon" />
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const CustomDropdownSingleCreate = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
  width,
  background,
}) => {
  const [dropdown, setDropdown] = useState("");
  const [masterDropdown, setMasterDropdown] = useState(
    placeholder
      ? placeholder
      : dropdownItems?.length > 0
      ? dropdownItems[0]
      : ""
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDropdownItems, setFilteredDropdownItems] =
    useState(dropdownItems);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setDropdown("");
  }, [masterDropdown]);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the dropdown items based on the search query
    const filteredItems = Object.entries(dropdownItems).filter(
      ([key, value]) => {
        // You can modify the condition based on your desired search logic
        return value.toLowerCase().includes(query.toLowerCase());
      }
    );

    // console.log(dropdownItems, "dropdownItems");
    // console.log(filteredItems, "filteredItems", query, "query");
    const convertedData = filteredItems.reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

    setFilteredDropdownItems(convertedData);
  };

  const handleInputClick = (event) => {
    event.stopPropagation();
    setSearchQuery(""); // Clear the search query when input is clicked
  };

  return (
    <div
      className={classNames.customDropdown}
      style={{
        width: width ? width : "",
      }}
    >
      <div
        className={classNames.dropdownBar}
        onClick={() => setDropdown(!dropdown)}
        ref={dropdownRef}
        style={{
          borderRadius: dropdown ? "5px 5px 0 0" : "",
          borderBottom: dropdown ? "none" : "",
          background: background ? background : "",
        }}
      >
        <span>{masterDropdown ? masterDropdown : ""}</span>
        <img src={arrowIcon} alt="arrowIcon" />
        <div
          className={classNames.dropdownOptions}
          style={{
            display: dropdown ? "" : "none",
            background: background ? background : "",
          }}
        >
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchInputChange}
            onClick={handleInputClick}
            placeholder="Search..."
          />
          {typeof dropdownItems === "object"
            ? Object.entries(filteredDropdownItems)?.map(([key, value]) => {
                return key === masterDropdown ? (
                  ""
                ) : (
                  <div
                    key={key}
                    name={field}
                    onClick={(event) => {
                      setMasterDropdown(value);
                      handleChange(event, field, key);
                    }}
                  >
                    {value}
                  </div>
                );
              })
            : filteredDropdownItems?.length > 0 &&
              filteredDropdownItems?.map((eachitem, i) => {
                return masterDropdown === eachitem ? (
                  ""
                ) : (
                  <div
                    key={eachitem + i}
                    name={field}
                    onClick={(event) => {
                      setMasterDropdown(eachitem);
                      handleChange(event, field, eachitem);
                    }}
                  >
                    {eachitem ? eachitem.toUpperCase() : ""}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export const CustomDropdownSingleLeadsOption = ({
  title,
  placeholder,
  state,
  value,
  dropdownItems,
  field,
  handleChange,
  width,
  background,
  typeoff,
  currentEnum,
}) => {
  const { globalUpdateItem, selectedContent } = useContext(MainContext);
  const [dropdown, setDropdown] = useState("");
  const [masterDropdown, setMasterDropdown] = useState(
    placeholder
      ? placeholder
      : dropdownItems?.length > 0
      ? dropdownItems[0]
      : ""
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setDropdown("");
  }, [masterDropdown]);

  return (
    <div
      className={classNames.customDropdown}
      style={{
        width: width ? width : "",
        pointerEvents:
          dropdownItems?.length > 0 && currentEnum?.toLowerCase() == "pending"
            ? ""
            : "none",
      }}
    >
      <div
        className={classNames.dropdownBar}
        onClick={() => setDropdown(!dropdown)}
        ref={dropdownRef}
        style={{
          borderRadius: dropdown ? "5px 5px 0 0" : "",
          borderBottom: dropdown ? "none" : "",
          background: background ? background : "",
        }}
      >
        <span>{masterDropdown ? masterDropdown : ""}</span>
        <img src={arrowIcon} alt="arrowIcon" />
        <div
          className={classNames.dropdownOptions}
          style={{
            display: dropdown ? "" : "none",
            background: background ? background : "",
          }}
        >
          {!typeoff && typeof dropdownItems == "object"
            ? Object?.entries(dropdownItems)?.map(([key, value]) => {
                return key === masterDropdown ? (
                  ""
                ) : (
                  <div
                    key={key}
                    name={field}
                    onClick={(event) => {
                      // console.log(value, "selectedItem obj");
                      setMasterDropdown(value);
                      handleChange(event, field, key, value);
                    }}
                  >
                    {value}
                  </div>
                );
              })
            : dropdownItems?.length > 0 &&
              dropdownItems?.map((eachitem, i) => {
                return masterDropdown == eachitem ? (
                  ""
                ) : (
                  <div
                    key={eachitem + i}
                    name={field}
                    onClick={(event) => {
                      // console.log(eachitem, "selectedItem array");
                      setMasterDropdown(eachitem);
                      handleChange(event, field, eachitem);
                    }}
                  >
                    {eachitem ? eachitem.toUpperCase() : ""}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};
