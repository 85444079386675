import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import classNames from "./mastercontent.module.scss";

//context
import { MainContext } from "../../context/context";

//assets
import editIcon from "../../assets/images/icons/edit.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import testImage from "../../assets/images/testimage.svg";
import {
  deleteItemsMasterDashboard,
  getItemDataWithId,
} from "../../assets/functions/master";
import ToggleButton from "../Toggle";

const CollectionTable = ({ dashboardLoading }) => {
  const { masterDashboard, masterSearch } = useContext(MainContext);

  const [canToggleBulk, setCanToggleBulk] = useState(false);

  useEffect(() => {
    if (masterDashboard?.length > 0) {
      let res = masterDashboard?.every(
        (item) => item?.isvaluepackage === false
      );
      setCanToggleBulk(res);
      // console.log(res, "res canToggleBulk");
    }
  }, [masterDashboard]);

  return (
    <div className={classNames.collectionTable}>
      <div className={classNames.header}>
        <div>S. No.</div>
        <div>Collection Name</div>
        <div>
          Favorite
          <ToggleButton
            status={canToggleBulk}
            // adminId={collection_id}
            field="popularCollection"
          />
        </div>
        <div>Images</div>
        <div>Actions</div>
      </div>
      <div className={classNames.body}>
        {dashboardLoading
          ? Array.from({ length: 3 }).map((_, index) => {
              return <EachListLoading key={"collectionloading" + index} />;
            })
          : masterDashboard?.length > 0
          ? masterDashboard
              ?.filter((eachitem) => {
                return (
                  eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
                  eachitem?.collection_id?.toLowerCase()?.includes(masterSearch)
                );
              })
              .map((eachitem, index) => {
                return <EachList key={"collection" + index} {...eachitem} />;
              })
          : ""}
      </div>
    </div>
  );
};

export default CollectionTable;

const EachList = ({ collection_id, name, images, isvaluepackage }) => {
  const {
    token,
    masterDeleteList,
    setMasterDeleteList,
    setOverlayOpened,
    setGlobalUpdateItem,
  } = useContext(MainContext);

  const firstTwoElements = images?.slice(0, 2);

  async function deleteItem(endpoint, tkn) {
    let result = await deleteItemsMasterDashboard(endpoint, tkn);
    if (result?.status) {
      setMasterDeleteList(!masterDeleteList);
    }
  }
  return (
    <div>
      <div>{collection_id ? collection_id : ""}</div>
      <div>{name ? name : ""}</div>
      <div>
        <ToggleButton
          status={isvaluepackage}
          adminId={collection_id}
          field="collection"
        />
      </div>
      <div>
        {firstTwoElements?.length > 0 &&
          firstTwoElements?.map((eachimg, index) => {
            return <img src={eachimg} alt="" key={"productimg" + index} />;
          })}
      </div>
      <div>
        <div
          className={classNames.blueBtn}
          onClick={async () => {
            //had to add id so we can edit the specific item
            let collectionById = await getItemDataWithId(
              "collection/admin/api/v1/get/" + collection_id,
              token
            );
            setGlobalUpdateItem(collectionById?.data?.data);
            // console.log(collectionById?.data?.data, "collectionById");
            setOverlayOpened("editcollection");
          }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
        <div
          className={classNames.blackBtn}
          onClick={() =>
            deleteItem("collection/admin/api/v1/delete/" + collection_id, token)
          }
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
};

const EachListLoading = () => {
  return (
    <div>
      <Skeleton width={70} height={20} />
      <Skeleton width={110} height={20} />
      <Skeleton width={80} height={20} />
      <div>
        <Skeleton width={40} height={40} />
        <Skeleton width={40} height={40} />
      </div>
      <div>
        <Skeleton width={50} height={50} />
        <Skeleton width={50} height={50} />
      </div>
    </div>
  );
};
