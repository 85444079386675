import axios from "axios";
import { URL } from "../static";
import { toast } from "react-toastify";

export async function handleLogin(email, password) {
  // console.log(email, password, "coming");
  try {
    const response = await axios.post(URL + "admin/api/v1/login", {
      email,
      password,
    });
    // console.log(response?.data, "login response");
    return response?.data;
    // redirect to dashboard or do something with response data
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function resetPasswordAPI(token, userid, password) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // console.log(userid, password, "resetPasswordAPI");
  const body = { password: password };
  try {
    const response = await axios.put(
      URL + "admin/api/v1/resetPassword/" + userid,
      password,
      { headers }
    );
    // console.log(response?.data, "reset password response");
    return response?.data;
    // redirect to dashboard or do something with response data
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function getAllAdmins(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(URL + "admin/api/v1/getall", { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function addAdminAPI(body, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.post(URL + "admin/api/v1/signup", body, {
      headers,
    });
    if (response?.data?.status) {
      toast("Successfully Added New Account!");
    } else {
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast("Cannot add  new account!");
    return error;
  }
}

export async function deleteAdmin(adminId, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.delete(
      URL + "admin/api/v1/delete/" + adminId,
      {
        headers,
      }
    );
    if (response?.data?.data?.status) {
      toast("Successfully deleted!");
    } else {
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast("Cannot delete account!");
    return error;
  }
}

export async function updateMailStatusAPI(id, body, token) {
  // console.log(body, token, "updatedCollection API");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const bodyy = { mailStatus: body };

  try {
    const response = await axios.put(
      URL + "admin/api/v1/toggleMailStatus/" + id,
      bodyy,
      {
        headers,
      }
    );
    if (response?.data?.status) {
      toast("Successfully changed mail status!");
    } else {
      toast("Can't update mail status try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
