import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";

import classNames from "./mastercontent.module.scss";

//context
import { MainContext } from "../../context/context";

//assets
import editIcon from "../../assets/images/icons/edit.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import testImage from "../../assets/images/testimage.svg";
import {
  deleteItemsMasterDashboard,
  getItemDataWithId,
} from "../../assets/functions/master";

const GalleryTable = ({ dashboardLoading }) => {
  const { masterDashboard, masterSearch } = useContext(MainContext);
  return (
    <div className={classNames.galleryTable}>
      <div className={classNames.header}>
        <div>S. No.</div>
        <div>User Image</div>
        <div>Actions</div>
      </div>
      <div className={classNames.body}>
        {dashboardLoading
          ? Array.from({ length: 3 }).map((_, index) => {
              return <EachListLoading key={"galleryloading" + index} />;
            })
          : masterDashboard?.length > 0
          ? masterDashboard
              ?.filter((eachitem) => {
                return eachitem?.gallery_id
                  ?.toLowerCase()
                  ?.includes(masterSearch);
              })
              .map((eachitem, index) => {
                return <EachList key={"gallery" + index} {...eachitem} />;
              })
          : ""}
      </div>
    </div>
  );
};

export default GalleryTable;

const EachList = ({ gallery_id, gallery_image }) => {
  const {
    token,
    masterDeleteList,
    setMasterDeleteList,
    setOverlayOpened,
    setGlobalUpdateItem,
  } = useContext(MainContext);

  async function deleteItem(endpoint, tkn) {
    let result = await deleteItemsMasterDashboard(endpoint, tkn);
    if (result?.status) {
      setMasterDeleteList(!masterDeleteList);
    }
  }

  return (
    <div>
      <div>{gallery_id ? gallery_id : ""}</div>
      {/* <div>{name ? name : ""}</div>
      <div>
        {firstTwoElements?.length > 0 &&
          firstTwoElements?.map((eachimg, index) => {
            return <img src={eachimg} alt="" key={"productimg" + index} />;
          })}
      </div> */}
      <div>
        <img
          src={gallery_image ? gallery_image : ""}
          alt=""
          key={"galleryimg"}
        />
      </div>
      <div>
        <div
          className={classNames.blackBtn}
          onClick={() =>
            deleteItem("gallery/admin/api/v1/delete/" + gallery_id, token)
          }
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
};

const EachListLoading = () => {
  return (
    <div>
      <Skeleton width={70} height={20} />
      <Skeleton width={110} height={20} />
      <div>
        <Skeleton width={50} height={50} />
        <Skeleton width={50} height={50} />
      </div>
    </div>
  );
};
