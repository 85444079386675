import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import classNames from "./mastercontent.module.scss";

//context
import { MainContext } from "../../context/context";

//assets
import editIcon from "../../assets/images/icons/edit.svg";
import deleteIcon from "../../assets/images/icons/delete.svg";
import testImage from "../../assets/images/testimage.svg";
import {
  deleteItemsMasterDashboard,
  getItemDataWithId,
} from "../../assets/functions/master";
import ToggleButton from "../Toggle";

const ProductTable = ({ dashboardLoading }) => {
  const { masterDashboard, masterSearch } = useContext(MainContext);

  const [canToggleBulk, setCanToggleBulk] = useState(false);

  useEffect(() => {
    if (masterDashboard?.length > 0) {
      let res = masterDashboard?.every(
        (item) => item?.isseasonalitem === false
      );
      setCanToggleBulk(res);
      // console.log(res, "res canToggleBulk");
    }
  }, [masterDashboard]);

  // console.log(masterDashboard, "masterDashboard product");
  return (
    <div className={classNames.productTable}>
      <div className={classNames.header}>
        <div>ID No.</div>
        <div>Product Name</div>
        <div>Product Price</div>
        <div>
          Popular
          <ToggleButton
            status={canToggleBulk}
            // adminId={collection_id}
            field="popularProduct"
          />
        </div>
        <div>Images</div>
        <div>Actions</div>
      </div>
      <div className={classNames.body}>
        {dashboardLoading
          ? Array.from({ length: 3 }).map((_, index) => {
            return <EachListLoading key={"productloading" + index} />;
          })
          : masterDashboard?.length > 0
            ? masterDashboard
              ?.filter((eachitem) => {
                return (
                  eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
                  eachitem?.product_id?.toLowerCase()?.includes(masterSearch)
                );
              })
              ?.map((eachitem, index) => {
                return <EachList key={"product" + index} {...eachitem} />;
              })
            : ""}
      </div>
    </div>
  );
};

export default ProductTable;

const EachList = ({ product_id, name, unit_type, price, images, isseasonalitem }) => {
  const {
    token,
    masterDeleteList,
    setMasterDeleteList,
    setOverlayOpened,
    setGlobalUpdateItem,
  } = useContext(MainContext);

  // const firstTwoElements = images?.slice(0, 2);

  async function deleteItem(endpoint, tkn) {
    let result = await deleteItemsMasterDashboard(endpoint, tkn);
    if (result?.status) {
      setMasterDeleteList(!masterDeleteList);
    }
  }
  const [balanceToggle, setBalanceToggle] = useState(false);

  return (
    <div>
      <div>{product_id ? product_id : ""}</div>
      <div>{name ? name : ""}&nbsp;<span style={{ display: !unit_type ? "none" : "" }}>-</span>&nbsp;{unit_type ? unit_type : ""}</div>
      <div>${price ? price : "0.00"}</div>
      <div>
        <ToggleButton
          status={isseasonalitem}
          adminId={product_id}
          field="product"
        />
      </div>
      <div>
        {images?.length > 0 &&
          images?.map((eachimg, index) => {
            return <img src={eachimg} alt="" key={"productimg" + index} />;
          })}
      </div>
      <div>
        <div
          className={classNames.blueBtn}
          onClick={async () => {
            //had to add id so we can edit the specific item
            let productById = await getItemDataWithId(
              "product/admin/api/v1/get/" + product_id,
              token
            );
            setGlobalUpdateItem(productById?.data?.data);
            // console.log(productById?.data?.data, "productById");
            setOverlayOpened("editproduct");
          }}
        >
          <img src={editIcon} alt="editIcon" />
        </div>
        <div
          className={classNames.blackBtn}
          onClick={() =>
            deleteItem("product/admin/api/v1/delete/" + product_id, token)
          }
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
};

const EachListLoading = () => {
  return (
    <div>
      <Skeleton width={70} height={20} />
      <Skeleton width={110} height={20} />
      <Skeleton width={80} height={20} />
      <Skeleton width={55} height={20} />
      <div>
        <Skeleton width={40} height={40} />
        <Skeleton width={40} height={40} />
      </div>
      <div>
        <Skeleton width={50} height={50} />
        <Skeleton width={50} height={50} />
      </div>
    </div>
  );
};
