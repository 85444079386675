import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import classNames from "./master.module.scss";

//context
import { MainContext } from "../../context/context";

//components
import MasterHeader from "../../components/MasterHeader";
import ProductTable from "../../components/MasterBody/ProductTable";
import CategoryTable from "../../components/MasterBody/CategoryTable";
import CollectionTable from "../../components/MasterBody/CollectionTable";
import BannerBody from "../../components/MasterBody/BannerBody";
import ReviewTable from "../../components/MasterBody/ReviewTable";

//assets
import { getMasterDashboard } from "../../assets/functions/master.js";
import GalleryTable from "../../components/MasterBody/GalleryTable";

const Master = () => {
  const {
    masterDropdown,
    masterDeleteList,
    loginData,
    setMasterDashboard,
    token,
    productCreated,
  } = useContext(MainContext);
  const [dashboardLoading, setDashboardLoading] = useState(false);

  useEffect(async () => {
    getDashboard();
  }, [loginData, masterDropdown, masterDeleteList, productCreated]);

  async function getDashboard() {
    setDashboardLoading(true);
    switch (masterDropdown) {
      case "Product":
        let productData = await getMasterDashboard(
          "product/admin/api/v1/getall",
          token
        );
        setMasterDashboard(productData?.data?.data);
        setDashboardLoading(false);
        // console.log(productData?.data?.data, "productData");
        break;
      case "Category":
        let categoryData = await getMasterDashboard(
          "category/admin/api/v1/getall",
          token
        );
        setMasterDashboard(categoryData?.data?.data);
        setDashboardLoading(false);
        // console.log(categoryData?.data?.data, "categoryData");
        break;
      case "Collection":
        let collectionData = await getMasterDashboard(
          "collection/admin/api/v1/getall",
          token
        );
        setMasterDashboard(collectionData?.data?.data);
        setDashboardLoading(false);
        // console.log(collectionData?.data?.data, "collectionData");
        break;
      case "Banner":
        let bannerData = await getMasterDashboard(
          "banner/admin/api/v1/getall",
          token
        );
        setMasterDashboard(bannerData?.data?.data);
        setDashboardLoading(false);
        // console.log(bannerData?.data?.data, "bannerData");
        break;
      case "Review":
        let reviewData = await getMasterDashboard(
          "review/admin/api/v1/getall",
          token
        );
        setMasterDashboard(reviewData?.data?.data);
        setDashboardLoading(false);
        // console.log(reviewData?.data?.data, "reviewData");
        break;
      case "Gallery":
        let galleryData = await getMasterDashboard(
          "gallery/admin/api/v1/getall",
          token
        );
        setMasterDashboard(galleryData?.data?.data);
        setDashboardLoading(false);
        // console.log(galleryData?.data?.data, "galleryData");
        break;
      default:
        break;
    }
  }

  function renderBody() {
    switch (masterDropdown) {
      case "Product":
        return <ProductTable dashboardLoading={dashboardLoading} />;
      case "Category":
        return <CategoryTable dashboardLoading={dashboardLoading} />;
      case "Collection":
        return <CollectionTable dashboardLoading={dashboardLoading} />;
      case "Banner":
        return <BannerBody dashboardLoading={dashboardLoading} />;
      case "Review":
        return <ReviewTable dashboardLoading={dashboardLoading} />;
      case "Gallery":
        return <GalleryTable dashboardLoading={dashboardLoading} />;
      default:
        break;
    }
  }

  if (!loginData && !token) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className={classNames.master}>
      <MasterHeader />
      {renderBody()}
    </div>
  );
};

export default Master;
