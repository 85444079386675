import React, { createContext, useState, useEffect } from "react";
import { getMapDropdowns } from "../assets/functions/map";
import { getAllAdmins } from "../assets/functions";

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [loginData, setLoginData] = useState("");
  const [token, setToken] = useState(localStorage.getItem("atozToken") || "");
  const [adminId, setAdminId] = useState(
    localStorage.getItem("atozAdminId") || ""
  );
  const [masterDropdown, setMasterDropdown] = useState("Product");
  const [masterSearch, setMasterSearch] = useState("");
  const [mapSearch, setMapSearch] = useState("");
  const [masterDeleteList, setMasterDeleteList] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [overlayOpened, setOverlayOpened] = useState("");
  const [selectedContent, setSelectedContent] = useState("");
  const [loginPage, setLoginPage] = useState("");
  const [overflowLoading, setOverflowLoading] = useState(false);
  const [allAdminsList, setAllAdminsList] = useState("");
  const [productCreated, setproductCreated] = useState(false);
  const [globalUpdateItem, setGlobalUpdateItem] = useState({});
  const [selectedNav, setSelectedNav] = useState(
    window?.location?.pathname || "Master"
  );

  //mapping dropdown items
  //  let categoryDropdownsLS = JSON.parse(
  //   localStorage.getItem("categoryDropdowns") || ""
  // );
  // let collectionDropdownsLS = JSON.parse(
  //   localStorage.getItem("collectionDropdowns") || ""
  // );
  // let productDropdownsLS = JSON.parse(
  //   localStorage.getItem("productDropdowns") || ""
  // );

  const [categoryWithId, setCategoryWithId] = useState({});
  const [collectionsWithId, setCollectionsWithId] = useState({});
  const [productsWithId, setProductsWithId] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(async () => {
    let categoryDropdowns;
    let collectionDropdowns;
    let productDropdowns;
    if (token) {
      categoryDropdowns = await getMapDropdowns(
        "category/admin/api/v1/getallids",
        token
      );
      collectionDropdowns = await getMapDropdowns(
        "collection/admin/api/v1/getallids",
        token
      );
      productDropdowns = await getMapDropdowns(
        "product/admin/api/v1/getallids",
        token
      );
      setCategoryWithId(categoryDropdowns?.data?.data);
      setCollectionsWithId(collectionDropdowns?.data?.data);
      setProductsWithId(productDropdowns?.data?.data);
      localStorage.setItem(
        "categoryDropdowns",
        JSON.stringify(categoryDropdowns?.data?.data)
      );
      localStorage.setItem(
        "collectionDropdowns",
        JSON.stringify(collectionDropdowns?.data?.data)
      );
      localStorage.setItem(
        "productDropdowns",
        JSON.stringify(productDropdowns?.data?.data)
      );
    }

    // console.log(categoryDropdowns?.data?.data, "categoryDropdowns");
    // console.log(collectionDropdowns?.data?.data, "collectionDropdowns");
    // console.log(productDropdowns?.data?.data, "productDropdowns");
  }, [token, selectedNav, productCreated]);

  useEffect(async () => {
    const storedLoginData = JSON.parse(localStorage.getItem("atozLoginData"));
    const categoryDropdownsData = JSON.parse(
      localStorage.getItem("categoryDropdowns")
    );
    const collectionDropdownsData = JSON.parse(
      localStorage.getItem("collectionDropdowns")
    );
    const productDropdownsData = JSON.parse(
      localStorage.getItem("productDropdowns")
    );
    if (storedLoginData) {
      setLoginData(storedLoginData);
    }
    if (categoryDropdownsData) {
      setCategoryWithId(categoryDropdownsData);
    }
    if (collectionDropdownsData) {
      setCollectionsWithId(collectionDropdownsData);
    }
    if (productDropdownsData) {
      setProductsWithId(productDropdownsData);
    }
    let allAdminsResponse = await getAllAdmins(token);
    if (allAdminsResponse?.status) {
      setAllAdminsList(allAdminsResponse?.data?.data);
      // console.log(allAdminsResponse?.data?.data, "allAdminsResponse");
    }
  }, [token, productCreated, selectedNav]);

  //master
  const [masterDashboard, setMasterDashboard] = useState("");

  //leads
  const [leadsDashboard, setLeadsDashboard] = useState("");
  const [selectedLeadsDashboard, setSelectedLeadsDashboard] = useState("");
  const [leadsDashboardLoading, setLeadsDashboardLoading] = useState(false);
  const [leadsUpdated, setLeadsUpdated] = useState(false);

  //create states
  const [createProduct, setCreateProduct] = useState({
    product_id: "",
    unit_type:"",
    name: "",
    description: "",
    // size: "",
    price: "",
    // tags: "",
    suggestions: [],
    images: "",
  });
  const [createCategory, setCreateCategory] = useState({
    name: "",
    images: "",
  });
  const [createCollection, setCreateCollection] = useState({
    name: "",
    images: "",
  });
  const [createBanner, setCreateBanner] = useState({
    image: "",
  });
  const [createReview, setCreateReview] = useState({
    name: "",
    description: "",
    images: "",
  });
  const [createGallery, setCreateGallery] = useState({
    image: "",
  });
  const [createMapping, setCreateMapping] = useState({
    category_id: "",
    collection_id: "",
    products_list: "",
  });
  const [addAdmin, setAddAdmin] = useState({
    email: "",
    password: "",
    name: "",
    role: "",
    mailStatus: true,
  });

  //update states
  const [resetPassword, setResetPassword] = useState({
    password: "",
  });
  const [updateCollection, setUpdateCollection] = useState({
    products_list: [],
  });

  //helper states
  const [selectedCollectionProducts, setSelectedCollectionProducts] =
    useState("");
  const [selectedCollectionName, setSelectedCollectionName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [firstTimeSuggested, setFirstTimeSuggested] = useState(true);

  //overlay elements

  useEffect(() => {
    setFirstTimeSuggested(true);
  }, [overlayOpened]);

  const allOverlays = [
    {
      title: "Create Product",
      btn1: "Close",
      btn2: "Create",
      createhandle: handleChangeProduct,
      contents: [
        {
          align: "flex",
          content: [
            {
              title: "Enter Product ID*",
              placeholder: "Enter Product ID",
              state: "",
              value: "",
              type: "CustomInput",
              field: "product_id",
            },
            {
              title: "Enter Product Price*",
              placeholder: "Enter Product Price",
              state: "",
              value: "",
              type: "CustomInput",
              field: "price",
            },
          ],
        },
        {
          title: "Enter Product Name*",
          placeholder: "Enter Product Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Enter Product Units*",
          placeholder: "Enter Product Units",
          state: "",
          value: "",
          type: "CustomInput",
          field: "unit_type",
        },
        
        // {
        //   title: "Enter Product Size",
        //   placeholder: "Enter Product Size",
        //   state: "",
        //   value: "",
        //   type: "CustomInput",
        //   field: "size",
        // },
        // {
        //   title: "Enter Product Tags",
        //   placeholder: "Enter Product Tags",
        //   state: "",
        //   value: "",
        //   type: "CustomInput",
        //   field: "tags",
        // },
        {
          title: "Enter Product Description*",
          placeholder: "Enter Product Description",
          state: "",
          value: "",
          type: "CustomTextArea",
          field: "description",
        },
        {
          title: "",
          placeholder: "Select Suggested Products*",
          state: "",
          value: "",
          type: "CustomDropdown",
          dropdownItems: productsWithId,
          field: "suggestions",
        },
        {
          title: "Upload Product Images*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Edit Product",
      btn1: "Close",
      btn2: "Update",
      createhandle: handleChangeProductUpdate,
      contents: [
        {
          align: "flex",
          content: [
            {
              title: "Enter Product ID*",
              placeholder: "Enter Product ID",
              state: "",
              value: "",
              type: "CustomInput",
              field: "product_id",
            },
            {
              title: "Enter Product Price*",
              placeholder: "Enter Product Price",
              state: "",
              value: "",
              type: "CustomInput",
              field: "price",
            },
          ],
        },
        {
          title: "Enter Product Name*",
          placeholder: "Enter Product Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Enter Product Units*",
          placeholder: "Enter Product Units",
          state: "",
          value: "",
          type: "CustomInput",
          field: "unit_type",
        },
        // {
        //   title: "Enter Product Size",
        //   placeholder: "Enter Product Size",
        //   state: "",
        //   value: "",
        //   type: "CustomInput",
        //   field: "size",
        // },
        // {
        //   title: "Enter Product Tags",
        //   placeholder: "Enter Product Tags",
        //   state: "",
        //   value: "",
        //   type: "CustomInput",
        //   field: "tags",
        // },
        {
          title: "Enter Product Description*",
          placeholder: "Enter Product Description",
          state: "",
          value: "",
          type: "CustomTextArea",
          field: "description",
        },
        {
          title: "",
          placeholder: "Select Suggested Products*",
          state: "",
          value: "",
          type: "CustomDropdown",
          dropdownItems: productsWithId,
          field: "suggestions",
        },
        {
          title: "Upload Product Images*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Create Category",
      btn1: "Close",
      btn2: "Create",
      createhandle: handleChangeCategory,
      contents: [
        {
          title: "Enter Category Name*",
          placeholder: "Enter Category Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Upload Category Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Edit Category",
      btn1: "Close",
      btn2: "Update",
      createhandle: handleChangeProductUpdate,
      contents: [
        {
          title: "Enter Category Name*",
          placeholder: "Enter Category Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Upload Category Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Create Collection",
      btn1: "Close",
      btn2: "Create",
      createhandle: handleChangeCollection,
      contents: [
        {
          title: "Enter Collection Name*",
          placeholder: "Enter Collection Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Enter Collection Description*",
          placeholder: "Enter Collection Description",
          state: "",
          value: "",
          type: "CustomInput",
          field: "description",
        },
        {
          title: "Upload Collection Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Edit Collection",
      btn1: "Close",
      btn2: "Update",
      createhandle: handleChangeProductUpdate,
      contents: [
        {
          title: "Enter Collection Name*",
          placeholder: "Enter Collection Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Enter Collection Description*",
          placeholder: "Enter Collection Description",
          state: "",
          value: "",
          type: "CustomInput",
          field: "description",
        },
        {
          title: "Upload Collection Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Create Banner",
      btn1: "Close",
      btn2: "Create",
      createhandle: handleChangeBanner,
      contents: [
        // {
        //   title: "Enter Banner Title*",
        //   placeholder: "Quality Rentals for all occasions",
        //   state: "",
        //   value: "",
        //   type: "CustomInput",
        // },
        {
          title: "Upload Banner Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "image",
        },
      ],
    },
    {
      title: "Create Review",
      btn1: "Close",
      btn2: "Create",
      createhandle: handleChangeReview,
      contents: [
        {
          title: "Enter User Name*",
          placeholder: "Enter User Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Enter Review*",
          placeholder: "Enter Product Description",
          state: "",
          value: "",
          type: "CustomTextArea",
          field: "description",
        },
        {
          title: "Upload User Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Edit Review",
      btn1: "Close",
      btn2: "Update",
      createhandle: handleChangeProductUpdate,
      contents: [
        {
          title: "Enter User Name*",
          placeholder: "Enter User Name",
          state: "",
          value: "",
          type: "CustomInput",
          field: "name",
        },
        {
          title: "Enter Review*",
          placeholder: "Enter Product Description",
          state: "",
          value: "",
          type: "CustomTextArea",
          field: "description",
        },
        {
          title: "Upload User Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "images",
        },
      ],
    },
    {
      title: "Create Gallery",
      btn1: "Close",
      btn2: "Add",
      createhandle: handleChangeGallery,
      contents: [
        {
          title: "Upload User Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "image",
        },
      ],
    },
    {
      title: "Edit Gallery",
      btn1: "Close",
      btn2: "Update",
      createhandle: handleChangeProductUpdate,
      contents: [
        {
          title: "Upload User Image*",
          placeholder: "",
          state: "",
          value: "",
          type: "CustomImageInput",
          field: "image",
        },
      ],
    },
    {
      title: "Create Mapping",
      btn1: "Close",
      btn2: "Map",
      createhandle: handleCreateMapping,
      contents: [
        {
          title: "",
          placeholder: "Select One Category*",
          state: "",
          value: "",
          type: "CustomDropdownSingle",
          field: "category_id",
          dropdownItems: categoryWithId,
        },
        {
          title: "",
          placeholder: "Select One Collection*",
          state: "",
          value: "",
          type: "CustomDropdownSingle",
          field: "collection_id",
          dropdownItems: collectionsWithId,
        },
        {
          title: "",
          placeholder: "Select Products*",
          state: "",
          value: "",
          type: "CustomDropdown",
          field: "products_list",
          dropdownItems: productsWithId,
        },
      ],
    },
    {
      title: "Lead Details",
      btn1: "",
      btn2: "",
      // createhandle: handleCreateMapping,
      show: "leadsoverlay",
      contents: [
        {
          title: "",
          placeholder: "Select One Category*",
          state: "",
          value: "",
          type: "CustomDropdownSingle",
          field: "category_id",
          dropdownItems: categoryWithId,
        },
        {
          title: "",
          placeholder: "Select One Collection*",
          state: "",
          value: "",
          type: "CustomDropdownSingle",
          field: "collection_id",
          dropdownItems: collectionsWithId,
        },
        {
          title: "",
          placeholder: "Select Products*",
          state: "",
          value: "",
          type: "CustomDropdown",
          field: "products_list",
          dropdownItems: productsWithId,
        },
      ],
    },
    {
      title: "Reset Password",
      btn1: "",
      btn2: "",
      createhandle: handleResetPassword,
      show: "resetpassword",
      contents: [
        // {
        //   title: "Enter Current Password*",
        //   placeholder: "Enter Current Password",
        //   state: "",
        //   value: "",
        //   type: "CustomInput",
        //   field: "",
        // },
        {
          title: "Set New Password*",
          placeholder: "Set New Password",
          state: "",
          value: "",
          type: "CustomInput",
          field: "password",
        },
        {
          title: "Confirm New Password*",
          placeholder: "Confirm New Password",
          state: "",
          value: "",
          type: "CustomInput",
          field: "",
        },
      ],
    },
    {
      title: "Reset Password",
      btn1: "",
      btn2: "",
      // createhandle: handleCreateMapping,
      show: "resetpassworddone",
      contents: [],
    },
    {
      title: "Admin Setting",
      btn1: "",
      btn2: "",
      // createhandle: handleCreateMapping,
      show: "adminsettings",
      contents: [],
    },
    {
      title: "Update Collection",
      btn1: "Close",
      btn2: "Update",
      createhandle: handleUpdateCollection,
      contents: [
        // {
        //   title: "",
        //   placeholder: "Select Products*",
        //   state: "",
        //   value: "",
        //   type: "CustomDropdown",
        //   field: "products_list",
        //   dropdownItems: productsWithId,
        // },
      ],
    },
  ];

  useEffect(() => {
    switch (overlayOpened) {
      case "createproduct":
        setSelectedContent(allOverlays[0]);
        break;
      case "editproduct":
        setSelectedContent(allOverlays[1]);
        break;
      case "createcategory":
        setSelectedContent(allOverlays[2]);
        break;
      case "editcategory":
        setSelectedContent(allOverlays[3]);
        break;
      case "createcollection":
        setSelectedContent(allOverlays[4]);
        break;
      case "editcollection":
        setSelectedContent(allOverlays[5]);
        break;
      case "createbanner":
        setSelectedContent(allOverlays[6]);
        break;
      case "createreview":
        setSelectedContent(allOverlays[7]);
        break;
      case "editreview":
        setSelectedContent(allOverlays[8]);
        break;
      case "creategallery":
        setSelectedContent(allOverlays[9]);
        break;
      case "editgallery":
        setSelectedContent(allOverlays[10]);
        break;
      case "createmapping":
        setSelectedContent(allOverlays[11]);
        break;
      case "viewmoreleads":
        setSelectedContent(allOverlays[12]);
        break;
      case "resetpassword":
        setSelectedContent(allOverlays[13]);
        break;
      case "resetpassworddone":
        setSelectedContent(allOverlays[14]);
        break;
      case "adminsettings":
        setSelectedContent(allOverlays[15]);
        break;
      case "updatecollection":
        setSelectedContent(allOverlays[16]);
        break;
      default:
        break;
    }
  }, [overlayOpened]);

  //updatee

  function handleChangeProductUpdate(event, field, valuee) {
    // console.log(event, "dropdown event change");
    // console.log(field, valuee, "field, valuee");
    const { name, value } = event?.target;

    // console.log(name, value, "name, value");
    if (field == "suggestions") {
      setGlobalUpdateItem((prev) => {
        return { ...prev, [field]: valuee };
      });
    } else if (name == "image") {
      let imagesArray = Array.from(event.target.files);
      setGlobalUpdateItem((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else if (name == "images") {
      let imagesArray = Array.from(event.target.files);
      setGlobalUpdateItem((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setGlobalUpdateItem((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  //update end

  function handleChangeProduct(event, field, valuee) {
    // console.log(event, "dropdown event change");
    const { name, value } = event?.target;

    if (field == "suggestions") {
      setCreateProduct((prev) => {
        return { ...prev, [field]: valuee };
      });
    }
    if (name == "images") {
      let imagesArray = Array.from(event.target.files);
      setCreateProduct((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setCreateProduct((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  function handleChangeCategory(event) {
    const { name, value } = event?.target;

    if (name == "images") {
      let imagesArray = Array.from(event.target.files);
      setCreateCategory((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setCreateCategory((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  function handleChangeCollection(event) {
    const { name, value } = event?.target;

    if (name == "images") {
      let imagesArray = Array.from(event.target.files);
      setCreateCollection((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setCreateCollection((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }
  function handleChangeBanner(event) {
    const { name, value } = event?.target;

    if (name == "image") {
      let imagesArray = Array.from(event.target.files);
      setCreateBanner((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setCreateBanner((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }
  function handleChangeGallery(event) {
    const { name, value } = event?.target;

    if (name == "image") {
      let imagesArray = Array.from(event.target.files);
      setCreateGallery((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setCreateGallery((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  function handleChangeReview(event) {
    const { name, value } = event?.target;

    if (name == "images") {
      let imagesArray = Array.from(event.target.files);
      setCreateReview((prev) => {
        return { ...prev, [name]: imagesArray };
      });
    } else {
      setCreateReview((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  function handleCreateMapping(event, field, valuee) {
    const { name, value } = event?.target;

    // console.log(field, valuee, "cheking changes create mapping");

    if (field && valuee) {
      setCreateMapping((prev) => {
        return { ...prev, [field]: valuee };
      });
    } else if (name && value) {
      setCreateMapping((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }

  function handleResetPassword(event, field, valuee) {
    const { name, value } = event?.target;

    // console.log(field, valuee, "cheking changes create mapping");

    if (name !== "") {
      // Only update state if field is not an empty string
      if (name === "images") {
        let imagesArray = Array.from(event.target.files);
        setResetPassword((prev) => {
          return { ...prev, [name]: imagesArray };
        });
      } else {
        setResetPassword((prev) => {
          return { ...prev, [name]: value };
        });
      }
    }
  }

  function handleUpdateCollection(event, field, valuee) {
    const { name, value } = event?.target;

    // console.log(field, valuee, "cheking changes create mapping");

    if (field && valuee) {
      setUpdateCollection((prev) => {
        return { ...prev, [field]: valuee };
      });
    } else if (name && value) {
      setUpdateCollection((prev) => {
        return { ...prev, [name]: value };
      });
    }
  }
  const value = {
    loginData,
    setLoginData,
    adminId,
    setAdminId,
    loginPage,
    setLoginPage,
    masterSearch,
    setMasterSearch,
    selectedNav,
    setSelectedNav,
    mapSearch,
    setMapSearch,
    masterDeleteList,
    setMasterDeleteList,
    globalLoading,
    setGlobalLoading,
    overflowLoading,
    setOverflowLoading,
    masterDropdown,
    setMasterDropdown,
    masterDashboard,
    setMasterDashboard,
    token,
    setToken,
    selectedContent,
    setSelectedContent,
    overlayOpened,
    setOverlayOpened,
    allAdminsList,
    setAllAdminsList,
    //dropdowns
    productsWithId,
    collectionsWithId,
    categoryWithId,
    //create states
    productCreated,
    setproductCreated,
    globalUpdateItem,
    setGlobalUpdateItem,
    createProduct,
    setCreateProduct,
    createCollection,
    setCreateCollection,
    createCategory,
    setCreateCategory,
    createBanner,
    setCreateBanner,
    createReview,
    setCreateReview,
    createGallery,
    setCreateGallery,
    createMapping,
    setCreateMapping,
    addAdmin,
    setAddAdmin,
    //leads
    leadsDashboard,
    setLeadsDashboard,
    selectedLeadsDashboard,
    setSelectedLeadsDashboard,
    leadsDashboardLoading,
    setLeadsDashboardLoading,
    leadsUpdated,
    setLeadsUpdated,

    //update states
    resetPassword,
    setResetPassword,
    updateCollection,
    setUpdateCollection,

    //helper states
    selectedCollectionProducts,
    setSelectedCollectionProducts,
    selectedCollectionName,
    setSelectedCollectionName,
    selectedId,
    setSelectedId,
    firstTimeSuggested,
    setFirstTimeSuggested,

    selectedItems,
    setSelectedItems,
  };
  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};

export default MainContextProvider;

export const MainContextConsumer = MainContext.Consumer;
