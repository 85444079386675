import React, { useContext, useEffect, useState } from "react";

import classNames from "./contactus.module.scss";

//assets
import editIcon from "../../assets/images/icons/edit.svg";
import { MainContext } from "../../context/context";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { URL } from "../../assets/static";
import { getLeadsDashboard } from "../../assets/functions/master";
import Skeleton from "react-loading-skeleton";
import {
  CustomDropdownSingle,
  CustomDropdownSingleLeadsOption,
} from "../../components/CustomInputs";
import {
  updateLeadsContactUsEnum,
  updateLeadsStatus,
} from "../../assets/functions/leads";

const ContactUs = () => {
  const navigate = useNavigate();
  const {
    loginData,
    token,
    leadsUpdated,
    leadsDashboard,
    setLeadsDashboard,
    leadsDashboardLoading,
    setLeadsDashboardLoading,
    masterSearch,
    setMasterSearch,
  } = useContext(MainContext);
  const [selectedLeads, setSelectedLeads] = useState("ContactUs");
  const [filterCondition, setFilterCondition] = useState("All");

  useEffect(async () => {
    setLeadsDashboardLoading(true);
    let leadsResponse = await getLeadsDashboard(
      "contactus/admin/api/v1/getall",
      token
    );
    let res = leadsResponse?.data?.data?.filter(
      (eachitem) => eachitem?.request_Type == "contactUs"
    );
    setLeadsDashboard(res);
    setLeadsDashboard(leadsResponse?.data?.data);
    setLeadsDashboardLoading(false);
    console.log(res, "contact us response");
  }, [leadsUpdated]);

  function handleChangeLeads(event, field, key, value) {
    setFilterCondition(value);
  }

  function switchLeadsFilter(eachitem) {
    switch (filterCondition) {
      case "Checked":
        if (masterSearch) {
          return (
            eachitem?.checked_status &&
            (eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.email?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.contact_id?.toLowerCase()?.includes(masterSearch))
          );
        } else {
          return eachitem?.checked_status;
        }
      case "Unchecked":
        if (masterSearch) {
          return (
            !eachitem?.checked_status &&
            (eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.email?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.contact_id?.toLowerCase()?.includes(masterSearch))
          );
        } else {
          return !eachitem?.checked_status;
        }
      case "Subscribed":
        if (masterSearch) {
          return (
            eachitem?.subscription &&
            (eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.email?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.contact_id?.toLowerCase()?.includes(masterSearch))
          );
        } else {
          return eachitem?.subscription;
        }
      default:
        if (masterSearch) {
          return (
            eachitem &&
            (eachitem?.name?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.email?.toLowerCase()?.includes(masterSearch) ||
              eachitem?.contact_id?.toLowerCase()?.includes(masterSearch))
          );
        } else {
          return eachitem;
        }
        break;
    }
  }

  if (!loginData && !token) {
    return <Navigate replace to="/" />;
  }

  return (
    <div className={classNames.leads}>
      <div className={classNames.contentTable}>
        <div className={classNames.leadsOptions}>
          <div
            className={`${classNames.options} ${
              selectedLeads == "ContactUs" ? classNames.selectedOption : ""
            }`}
            onClick={(event) => setSelectedLeads(event?.target?.innerText)}
          >
            ContactUs
          </div>
        </div>
        <div className={classNames.leadsFilter}>
          <CustomDropdownSingle
            placeholder="Filter"
            dropdownItems={["All", "Checked", "Unchecked", "Subscribed"]}
            handleChange={handleChangeLeads}
            width="170px"
            background="#FFC9BD"
          />
          <div className={classNames.searchBar}>
            <input
              type="text"
              placeholder="search"
              onChange={(event) =>
                setMasterSearch(event?.target?.value?.toLowerCase())
              }
            />
            <div>Search</div>
          </div>
        </div>
        <div className={classNames.leadsBody}>
          <div className={classNames.header}>
            <div>Status</div>
            <div>Contract Id</div>
            <div>TimeStamp</div>
            <div>Name</div>
            <div>ContactUs Enum</div>
            <div>Email ID</div>
          </div>
          <div className={classNames.body}>
            {leadsDashboardLoading
              ? Array.from({ length: 3 }).map((_, index) => {
                  return <EachListLoading key={"categoryloading" + index} />;
                })
              : leadsDashboard?.length > 0
              ? leadsDashboard
                  ?.filter((eachitem) => {
                    return switchLeadsFilter(eachitem);
                  })
                  .map((eachlead, index) => {
                    return (
                      <EachList
                        {...eachlead}
                        full={eachlead}
                        key={"leads" + index}
                      />
                    );
                  })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

const EachList = ({
  name,
  phone,
  email,
  subscription,
  created_at,
  wishlist,
  checked_status,
  full,
  contact_id,
  contactUsEnum,
}) => {
  const [checked, setChecked] = useState("");
  const [passingDropdown, setPassingDropdown] = useState([
    "Failed",
    "Completed",
  ]);
  const {
    token,
    setOverlayOpened,
    setSelectedLeadsDashboard,
    setLeadsUpdated,
    setLeadsDashboardLoading,
  } = useContext(MainContext);

  const handleCheckboxChange = async (event) => {
    setLeadsDashboardLoading(true);
    setChecked(event?.target?.checked);
    // console.log(contact_id, "checled", event?.target?.checked);
    let updateLeadsStatusAPI = await updateLeadsStatus(
      "contactus/admin/api/v1/update/" + contact_id,
      token
    );
    setLeadsUpdated((prev) => !prev);
  };

  async function handleChangeLeads(event, field, key, value) {
    setLeadsDashboardLoading(true);
    let updatedContactUsEnum;

    if (value?.toLowerCase() == "completed") {
      updatedContactUsEnum = 1;
    } else if (value?.toLowerCase() == "failed") {
      updatedContactUsEnum = 2;
    } else {
      updatedContactUsEnum = 0;
    }

    // console.log(updatedContactUsEnum, "updatedContactUsEnum", value);

    let updateLeadsStatusAPI = await updateLeadsContactUsEnum(
      "contactus/admin/api/v1/updateContactUsEnum/" + contact_id,
      token,
      updatedContactUsEnum
    );

    console.log(updateLeadsStatusAPI, "updateLeadsStatusAPI enum");
    setLeadsDashboardLoading(false);

    //to update leads page after API change
    setLeadsUpdated((prev) => !prev);
  }

  return (
    <div className={classNames.eachLeads}>
      <div className={classNames.eachLeadsCheckbox}>
        <input
          type="checkbox"
          checked={checked ? checked : checked_status}
          onChange={handleCheckboxChange}
        ></input>
      </div>
      <div className={classNames.contractId}>
        {contact_id ? contact_id : ""}
      </div>
      <div className={classNames.date}>
        {created_at?.date ? created_at?.date : ""}
      </div>
      <div className={classNames.name}>{name ? name : ""}</div>
      {/* <div className={classNames.phone}>{phone ? phone : ""} </div> */}
      <div className={classNames.phone}>
        <CustomDropdownSingleLeadsOption
          placeholder={contactUsEnum}
          dropdownItems={passingDropdown}
          handleChange={handleChangeLeads}
          width="95%"
          background="#FFC9BD"
          currentEnum={contactUsEnum}
        />
      </div>
      <div className={classNames.email}>
        {subscription ? <span></span> : ""}
        {email ? email : ""}
      </div>
    </div>
  );
};

const EachListLoading = () => {
  return (
    <div className={classNames.loadingParent}>
      <div>
        <Skeleton width={25} height={25} />
      </div>
      <Skeleton height={20} width={50} />
      <Skeleton height={20} width={50} />
      <Skeleton height={20} width={70} />
      <div>
        <Skeleton height={20} width={140} />
      </div>
      <div>
        <Skeleton height={20} width={30} />
        <Skeleton height={20} width={50} />
      </div>
      <div>
        <Skeleton height={20} width={70} />
      </div>
    </div>
  );
};

export const LeadsOverflow = () => {
  const { selectedLeadsDashboard } = useContext(MainContext);
  return (
    <div className={classNames.leadsOverflow}>
      <div className={classNames.details}>
        <span className={classNames.title}>Time Stamp :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.created_at?.date
            ? selectedLeadsDashboard?.created_at?.date
            : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Name :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.name ? selectedLeadsDashboard?.name : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Phone Number :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.phone ? selectedLeadsDashboard?.phone : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Company Name :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.company_name
            ? selectedLeadsDashboard?.company_name
            : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Email ID :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.email ? selectedLeadsDashboard?.email : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Event Time :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.event_time
            ? selectedLeadsDashboard?.event_time
            : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Best time to call :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.best_time_to_call
            ? selectedLeadsDashboard?.best_time_to_call
            : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Promocode :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.promo_code
            ? selectedLeadsDashboard?.promo_code
            : ""}
        </span>
      </div>
      <div className={classNames.details}>
        <span className={classNames.title}>Message :</span>
        <span className={classNames.content}>
          {selectedLeadsDashboard?.message
            ? selectedLeadsDashboard?.message
            : ""}
        </span>
      </div>
      <div
        className={classNames.pdfBtn}
        onClick={() => {
          if (selectedLeadsDashboard?.wishlist) {
            window.open(selectedLeadsDashboard?.wishlist, "_blank");
          }
        }}
      >
        PDF
      </div>
    </div>
  );
};
