import axios from "axios";
import { URL } from "../static";
import { toast } from "react-toastify";

export async function getMasterDashboard(endpoint, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(URL + endpoint, { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function deleteItemsMasterDashboard(endpoint, token, imagelink) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    let response = await axios.delete(URL + endpoint, { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function deleteImagesMasterDashboard(endpoint, token, imagelink) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    let response = await axios.put(
      URL + endpoint,
      { image: imagelink },
      { headers }
    );

    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

//createitems

export async function createItemsMasterDashboard(endpoint, token, statevalue) {
  // console.log(statevalue, "review value when pass");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();

  for (const key in statevalue) {
    // console.log(key, "all keys");
    if (key === "images") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("images", statevalue[key][i]);
        }
      } else {
        formData.append("images", statevalue[key]);
      }
    } else if (key === "image") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("image", statevalue[key][i]);
        }
      } else {
        formData.append("image", statevalue[key]);
      }
    } else if (key === "suggestions") {
      formData.append("suggestions", statevalue[key]);
    } else if (key == "undefined") {
      // console.log("there's undefined value in object");
    } else {
      formData.set(key, statevalue[key]);
    }
  }

  try {
    const response = await axios.post(URL + endpoint, formData, { headers });
    // console.log(response, "create products");
    if (response?.data?.data?.status) {
      toast("Successfully executed!");
    } else {
      toast("Cannot create item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast(error?.message);
    return error;
  }
}

export async function forgetPassword(email) {
  try {
    const response = await axios.get(
      URL + "admin/api/v1/forgotpassword/" + email
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function getMapDashboard(endpoint, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(URL + endpoint, { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function createMappingFunc(endpoint, token, statevalue) {
  // console.log(statevalue, "create mapping state");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.post(URL + endpoint, statevalue, { headers });
    // console.log(response, "create mapping");
    if (response?.data?.data?.status) {
      toast("Successfully executed!");
    } else {
      toast("Cannot create item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast(error?.message);
    return error;
  }
}

export async function getLeadsDashboard(endpoint, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(URL + endpoint, { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function getItemDataWithId(endpoint, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.get(URL + endpoint, { headers });
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function updateMasterDashboard(endpoint, statevalue, token) {
  // console.log(endpoint, statevalue, token, "updateMasterDashboard");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const formData = new FormData();

  for (const key in statevalue) {
    // console.log(key, "all keys");
    if (key === "images") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("images", statevalue[key][i]);
        }
      } else {
        formData.append("images", statevalue[key]);
      }
    } else if (key === "image") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("image", statevalue[key][i]);
        }
      } else {
        formData.append("image", statevalue[key]);
      }
    } else if (key === "suggestions") {
      formData.append("suggestions", statevalue[key]);
    } else if (key == "undefined") {
      // console.log("there's undefined value in object");
    } else {
      formData.set(key, statevalue[key]);
    }
  }
  try {
    const response = await axios.put(URL + endpoint, formData, { headers });
    if (response?.data?.status) {
      toast("Successfully updated!");
    } else {
      toast("Cannot update item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
// export async function updateMasterDashboard(endpoint, body, token) {
//   console.log(endpoint, body, token, "updateMasterDashboard");
//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };
//   try {
//     const response = await axios.put(URL + endpoint, body, { headers });
//     if (response?.data?.status) {
//       toast("Successfully updated!");
//     } else {
//       toast("Cannot update item try again!");
//     }
//     return response?.data;
//   } catch (error) {
//     console.error(error);
//     return error;
//   }
// }

export async function updateImagesAPI(endpoint, token, valuee) {
  // console.log(statevalue, "create mapping state");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let statevalue = { images: valuee };

  const formData = new FormData();

  for (const key in statevalue) {
    // console.log(key, "all keys");
    if (key === "images") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("images", statevalue[key][i]);
        }
      } else {
        formData.append("images", statevalue[key]);
      }
    } else if (key === "image") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("image", statevalue[key][i]);
        }
      } else {
        formData.append("image", statevalue[key]);
      }
    } else if (key === "suggestions") {
      formData.append("suggestions", statevalue[key]);
    } else if (key == "undefined") {
      // console.log("there's undefined value in object");
    } else {
      formData.set(key, statevalue[key]);
    }
  }

  try {
    const response = await axios.put(URL + endpoint, formData, { headers });
    // console.log(response, "create mapping");
    if (response?.data?.data?.status) {
      toast("Successfully executed!");
    } else {
      toast("Cannot create item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast(error?.message);
    return error;
  }
}

export async function toggleValueCollection(token, id) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(
      URL + "collection/admin/api/v1/updateValuePackage/" + id,
      {},
      { headers }
    );
    if (response?.data?.status) {
      toast("Successfully updated!");
    } else {
      toast("Cannot update item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function toggleValueProduct(token, id) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(
      URL + "product/admin/api/v1/updateSeasonalItem/" + id,
      {},
      { headers }
    );
    if (response?.data?.status) {
      toast("Successfully updated!");
    } else {
      toast("Cannot update item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function uploadImageDashboardProduct(
  token,
  productid,
  statevalue
) {
  console.log(statevalue, "review value when pass");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const formData = new FormData();

  for (const key in statevalue) {
    if (key === "images") {
      if (Array.isArray(statevalue[key])) {
        for (let i = 0; i < statevalue[key].length; i++) {
          formData.append("images", statevalue[key][i]);
        }
      } else {
        formData.append("images", statevalue[key]);
      }
    }
  }

  try {
    const response = await axios.put(
      URL + "product/admin/api/v1/addimage/" + productid,
      formData,
      { headers }
    );
    console.log(response, "create products image");
    if (response?.data?.data?.status) {
      toast("Successfully added new image to product!");
    } else {
      toast("Cannot add image to product try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    toast(error?.message);
    return error;
  }
}

export async function toggleValueBulk(token, URI) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(URL + URI, {}, { headers });
    if (response?.data?.status) {
      toast("Successfully updated!");
    } else {
      toast("Cannot update item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
