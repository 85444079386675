import axios from "axios";
import { URL } from "../static";
import { toast } from "react-toastify";

export async function updateLeadsStatus(endpoint, token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(URL + endpoint, {}, { headers });
    if (response?.data?.status) {
      toast("Successfully executed!");
    } else {
      toast("Cannot create item try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function updateLeadsContactUsEnum(endpoint, token, body) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(
      URL + endpoint,
      { contactUsEnum: body },
      { headers }
    );
    if (response?.data?.status) {
      toast("Successfully executed!");
    } else {
      toast("Cannot update contactUsEnum try again!");
    }
    return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
