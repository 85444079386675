import React, { useContext, useState } from "react";

import classNames from "./login.module.scss";

//assest
import loginDesign from "../../assets/images/logindesign.svg";
import loginForgetPassDesign from "../../assets/images/loginforgetpassdesign.svg";
import fullLogo from "../../assets/images/fulllogo.svg";

//react-icons
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { handleLogin } from "../../assets/functions";
import { MainContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../../assets/functions/master";

const Login = () => {
  const navigate = useNavigate();
  const {
    setLoginData,
    setToken,
    setAdminId,
    setGlobalLoading,
    loginPage,
    setLoginPage,
  } = useContext(MainContext);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgetPasswordMessage, setForgetPasswordMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function loginFunction() {
    setErrorMessage("");
    setGlobalLoading(true);
    let data = await handleLogin(loginEmail, loginPassword);
    if (data?.status) {
      setLoginData(data?.data);
      localStorage.setItem("atozLoginData", JSON.stringify(data?.data));
      localStorage.setItem("atozToken", data?.data?.token);
      localStorage.setItem("atozAdminId", data?.data?.admin_id);
      localStorage.setItem("atozAdminStatus", data?.data?.role);
      setToken(data?.data?.token);
      setAdminId(data?.data?.admin_id);
      setGlobalLoading(false);
      if (data?.data?.role == "admin") navigate("/leads");
      else
      navigate("/master");
    } else {
      setErrorMessage("Enter valid credentials...");
      setGlobalLoading(false);
    }
  }

  return (
    <div className={classNames.login}>
      <div className={classNames.leftContainer}>
        {loginPage ? (
          <img
            src={loginForgetPassDesign}
            alt="loginForgetPassDesign"
            style={{ top: "0", height: "100%", maxHeight: "100%" }}
          />
        ) : (
          <img src={loginDesign} alt="loginDesign" />
        )}
      </div>
      <div className={classNames.rightContainer}>
        <div>
          <img src={fullLogo} alt="fullLogo" />
        </div>

        {loginPage == "forgetpass" ? (
          <div className={classNames.loginCredentials}>
            <div>
              <input
                type="email"
                onChange={(event) => setLoginEmail(event?.target?.value)}
              />
              <div className={classNames.heading}>Email</div>
            </div>
            <div
              className={classNames.loginBtn}
              onClick={async () => {
                setGlobalLoading(true);
                let res = await forgetPassword(loginEmail);
                if (res?.status) {
                  setForgetPasswordMessage(
                    "Temporary Credetinals Sent to your Email"
                  );
                } else {
                  setForgetPasswordMessage("Failed to execute reset password");
                }
                setLoginPage("forgetpassdone");
                setGlobalLoading(false);
              }}
              style={{
                pointerEvents: loginEmail ? "" : "none",
              }}
            >
              Submit
            </div>
          </div>
        ) : loginPage == "forgetpassdone" ? (
          <div className={classNames.forgetPasswordMessage}>
            <div className={classNames.message}>{forgetPasswordMessage}</div>
            <div
              className={classNames.goBackBtn}
              onClick={() => setLoginPage("")}
            >
              Go Back
            </div>
          </div>
        ) : (
          <div className={classNames.loginCredentials}>
            <div>
              <input
                type="email"
                onChange={(event) => setLoginEmail(event?.target?.value)}
              />
              <div className={classNames.heading}>Email</div>
            </div>
            <div>
              <input
                type={showPassword ? "text" : "password"}
                onChange={(event) => setLoginPassword(event?.target?.value)}
              />
              <div className={classNames.heading}>Password</div>
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            {errorMessage && (
              <div className={classNames.errorMessage}>{errorMessage}</div>
            )}
            <div
              className={classNames.loginBtn}
              onClick={() => loginFunction()}
              style={{
                pointerEvents: loginEmail && loginPassword ? "" : "none",
              }}
            >
              Log In
            </div>
            <div
              className={classNames.forgetPass}
              onClick={() => setLoginPage("forgetpass")}
            >
              Forgot Password?
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
