import React, { useContext, useEffect, useRef, useState } from "react";

import classNames from "./masterheader.module.scss";

//assets
import arrowIcon from "../../assets/images/icons/arrow.svg";
import { MainContext } from "../../context/context";

const MasterHeader = () => {
  const { masterDropdown, setMasterSearch, setOverlayOpened } =
    useContext(MainContext);

  function switchCreate() {
    switch (masterDropdown) {
      case "Product":
        setOverlayOpened("createproduct");
        break;
      case "Category":
        setOverlayOpened("createcategory");
        break;
      case "Collection":
        setOverlayOpened("createcollection");
        break;
      case "Banner":
        setOverlayOpened("createbanner");
        break;
      case "Review":
        setOverlayOpened("createreview");
        break;
      case "Gallery":
        setOverlayOpened("creategallery");
        break;
      default:
        break;
    }
  }

  return (
    <div className={classNames.masterHeader}>
      <CustomDropdown />
      <div className={classNames.searchBar}>
        <input
          type="text"
          placeholder="search"
          onChange={(event) =>
            setMasterSearch(event?.target?.value?.toLowerCase())
          }
        />
        <div>Search</div>
      </div>
      <div className={classNames.createBtn} onClick={switchCreate}>
        Create
      </div>
    </div>
  );
};

export default MasterHeader;

const CustomDropdown = () => {
  const [dropdown, setDropdown] = useState("");
  const { masterDropdown, setMasterDropdown } = useContext(MainContext);

  const dropdownItems = [
    "Product",
    "Category",
    "Collection",
    "Banner",
    "Review",
    "Gallery",
  ];

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown("");
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setDropdown("");
  }, [masterDropdown]);

  return (
    <div
      className={classNames.customDropdown}
      onClick={() => setDropdown(!dropdown)}
      style={{
        borderRadius: dropdown ? "5px 5px 0 0" : "",
        borderBottom: dropdown ? "none" : "",
      }}
      ref={dropdownRef}
    >
      <span>{masterDropdown ? masterDropdown : ""}</span>
      <img src={arrowIcon} alt="arrowIcon" />
      <div
        className={classNames.dropdownOptions}
        style={{ display: dropdown ? "" : "none" }}
      >
        {dropdownItems?.length > 0 &&
          dropdownItems?.map((eachitem, i) => {
            return masterDropdown == eachitem ? (
              ""
            ) : (
              <div
                key={eachitem + i}
                onClick={() => {
                  setMasterDropdown(eachitem);
                }}
              >
                {eachitem}
              </div>
            );
          })}
      </div>
    </div>
  );
};
