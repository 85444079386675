import React, { useContext } from "react";
import Skeleton from "react-loading-skeleton";

import classNames from "./mastercontent.module.scss";
import { MainContext } from "../../context/context";

//assets
import deleteIcon from "../../assets/images/icons/delete.svg";
import { deleteItemsMasterDashboard } from "../../assets/functions/master";

const BannerBody = ({ dashboardLoading }) => {
  const { masterDashboard } = useContext(MainContext);

  return (
    <div className={classNames.bannerContent}>
      {/* <div>
        <div className={classNames.title}>Title:</div>
        <input type="text" placeholder="Enter title here..." />
      </div> */}
      {masterDashboard?.length > 0 ? (
        masterDashboard?.map((eachbanner, i) => {
          return <EachItem {...eachbanner} index={i} key={"banner" + i} />;
        })
      ) : (
        <div>
          <Skeleton width={500} height={150} />
        </div>
      )}

      {/* <div className={classNames.updateBtn}>Update</div> */}
    </div>
  );
};

export default BannerBody;

const EachItem = ({ banner_image, banner_id, index }) => {
  const { token, masterDeleteList, setMasterDeleteList } =
    useContext(MainContext);

  async function deleteItem(endpoint, tkn) {
    let result = await deleteItemsMasterDashboard(endpoint, tkn, banner_image);
    if (result?.status) {
      setMasterDeleteList(!masterDeleteList);
    }
  }

  return (
    <div className={classNames.eachBanner}>
      <div className={classNames.title}>Banner {index + 1} :</div>
      <div className={classNames.bannerContainer}>
        <img src={banner_image} alt="bannerimg" />
        <div
          className={classNames.blackBtn}
          onClick={() =>
            deleteItem("banner/admin/api/v1/delete/" + banner_id, token)
          }
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </div>
      </div>
    </div>
  );
};
