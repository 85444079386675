import React, { useContext } from "react";

import classNames from "./overlay.module.scss";
import OverlayContent from "./OverlayContent";
import { MainContext } from "../../context/context";
import SettingsOverlay from "./SettingsOverlay";
import AdminSettings from "./AdminSettings";

const Overlay = () => {
  const { overlayOpened, setOverlayOpened } = useContext(MainContext);
  return (
    <div className={classNames.overlay}>
      <div
        className={classNames.greyTilt}
        onClick={() => setOverlayOpened("")}
      ></div>
      {overlayOpened?.includes("admin") ? (
        <AdminSettings />
      ) : overlayOpened == "resetpassword" ||
        overlayOpened == "resetpassworddone" ? (
        <SettingsOverlay />
      ) : (
        <OverlayContent />
      )}
    </div>
  );
};

export default Overlay;
