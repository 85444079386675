import React, { useContext } from "react";
import { MainContext } from "../../context/context";
import { useNavigate } from "react-router-dom";

import classNames from "./settings.module.scss";

//assets
import adminIcon from "../../assets/images/icons/admin.svg";
import resetpasswordIcon from "../../assets/images/icons/resetpassword.svg";

const Settings = () => {
  const navigate = useNavigate();
  const { loginData, token, setOverlayOpened } = useContext(MainContext);

  if (!loginData && !token) {
    navigate("/");
  }
  return (
    <div className={classNames.settings}>
      <div className={classNames.title}>Settings</div>
      <div className={classNames.settingBody}>
        <div
          className={classNames.settingsOption}
          onClick={() => setOverlayOpened("adminsettings")}
        >
          <img src={adminIcon} alt="adminIcon" />
          <span>Admin Setting</span>
        </div>
        <div className={classNames.settingsOption}>
          <img src={resetpasswordIcon} alt="resetpasswordIcon" />
          <span
            onClick={() => {
              setOverlayOpened("resetpassword");
            }}
          >
            Reset Password
          </span>
        </div>
      </div>
    </div>
  );
};

export default Settings;
