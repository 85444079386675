import React, { useContext } from "react";

import classNames from "./overlay.module.scss";
import { MainContext } from "../../context/context";

import {
  CustomDropdown,
  CustomDropdownCreate,
  CustomDropdownSingle,
  CustomDropdownSingleCreate,
  CustomImageInput,
  CustomImageInputCreate,
  CustomInput,
  CustomInputCreate,
  CustomSearch,
  CustomSearchCreate,
  CustomTextArea,
  CustomTextAreaCreate,
} from "../CustomInputs";

import successImage from "../../assets/images/success.svg";
import { resetPasswordAPI } from "../../assets/functions";
import { GlobalOverflowLoading } from "../GlobalLoading";

const SettingsOverlay = () => {
  const {
    selectedContent,
    overlayOpened,
    setOverlayOpened,
    adminId,
    resetPassword,
    overflowLoading,
    setOverflowLoading,
    token,
  } = useContext(MainContext);

  async function resetPasswordFunc() {
    // console.log(adminId, resetPassword, "resetPassword");
    setOverflowLoading(true);
    let resetPasswordResponse = await resetPasswordAPI(token, adminId, resetPassword);
    if (resetPasswordResponse?.data?.status) {
      setOverlayOpened("resetpassworddone");
    } else {
      setOverlayOpened("");
    }
    // console.log(resetPasswordResponse, "resetPassword response from API");
    setOverflowLoading(false);
  }

  function renderInputs(eachItem, index) {
    if (
      selectedContent?.title?.toLowerCase()?.includes("update") ||
      selectedContent?.title?.toLowerCase()?.includes("edit")
    ) {
      switch (eachItem?.type) {
        case "CustomInput":
          return (
            <CustomInput
              {...eachItem}
              key={"CustomInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomTextArea":
          return (
            <CustomTextArea
              {...eachItem}
              key={"CustomTextArea" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdown":
          return (
            <CustomDropdown
              {...eachItem}
              key={"CustomDropdown" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomImageInput":
          return (
            <CustomImageInput
              {...eachItem}
              key={"CustomImageInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomSearch":
          return (
            <CustomSearch
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdownSingle":
          return (
            <CustomDropdownSingle
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        default:
          return;
      }
    } else {
      switch (eachItem?.type) {
        case "CustomInput":
          return (
            <CustomInputCreate
              {...eachItem}
              key={"CustomInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomTextArea":
          return (
            <CustomTextAreaCreate
              {...eachItem}
              key={"CustomTextArea" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdown":
          return (
            <CustomDropdownCreate
              {...eachItem}
              key={"CustomDropdown" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomImageInput":
          return (
            <CustomImageInputCreate
              {...eachItem}
              key={"CustomImageInput" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomSearch":
          return (
            <CustomSearchCreate
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        case "CustomDropdownSingle":
          return (
            <CustomDropdownSingleCreate
              {...eachItem}
              key={"CustomSearch" + index}
              handleChange={selectedContent?.createhandle}
            />
          );
        default:
          return;
      }
    }
  }

  return (
    <div className={classNames.settingsOverlay}>
      <div className={classNames.header}>
        <span>{selectedContent?.title ? selectedContent?.title : ""}</span>
        <span onClick={() => setOverlayOpened("")}>X</span>
      </div>
      <div className={classNames.content}>
        {overlayOpened == "resetpassworddone" ? (
          <div className={classNames.resetPassDoneContainer}>
            <img src={successImage} alt="successImage" />
            <div className={classNames.passwordResetDone}>
              Password Changed Successfully{" "}
            </div>
            <div
              className={classNames.resetBtn}
              onClick={() => {
                setOverlayOpened("");
              }}
            >
              Go Back
            </div>
          </div>
        ) : (
          selectedContent?.contents?.length > 0 &&
          selectedContent?.contents.map((eachitem, index) => {
            return eachitem?.align == "flex" ? (
              <div
                style={{ display: "flex", gap: "1.5rem" }}
                key={"flex" + index}
              >
                {eachitem?.content?.map((eachItem, index) => {
                  return renderInputs(eachItem, index);
                })}
              </div>
            ) : (
              renderInputs(eachitem, index)
            );
          })
        )}
        <div
          className={classNames.resetBtn}
          style={{ display: overlayOpened == "resetpassword" ? "" : "none" }}
          onClick={() => {
            // console.log("reset password clicked");
            resetPasswordFunc();
          }}
        >
          Reset Password
        </div>
      </div>
      {overflowLoading && <GlobalOverflowLoading />}
    </div>
  );
};

export default SettingsOverlay;
